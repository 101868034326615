<!--
 * @Author: zl
 * @Date: 2021-11-04 11:05:20
 * @LastEditTime: 2022-04-19 11:07:01
 * @LastEditors: zl
 * @Description: 标段查看公用页
-->
<template>
	<el-dialog :visible.sync="dialogObj.show"
			   title="查看标段"
			   width="75%"
			   append-to-body
			   top="4vh"
			   center>
		<el-row class="sectionLook-padding">
			<el-collapse v-model="activeName"
						 class="custom-collapse">
				<el-collapse-item title="#1 标段信息"
								  name="1">
					<el-form ref="sectionForm"
							 :model="sectionForm"
							 size="small"
							 label-width="200px">
						<el-row>
							<el-col :span="12">
								<el-form-item label="标段编号："
											  prop="packNo">
									<div class="text-overflow-by">
										{{ sectionForm.packNo || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段名称："
											  prop="sname">
									<div class="text-overflow-by">
										{{ sectionForm.sname || '暂无' }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段(包)类型："
											  prop="packType">
									<div class="text-overflow-by">
										{{ sectionForm.packTypeName || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="合同工期："
											  prop="duration">
									<div class="text-overflow-by">
										{{ sectionForm.duration + " 天" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目标段负责人："
											  prop="packPrincipal">
									<div class="text-overflow-by">
										{{ sectionForm.packPrincipalName || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段负责人联系方式："
											  prop="constract">
									<div class="text-overflow-by">
										{{ sectionForm.constract || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段预算金额："
											  prop="buget">
									<div class="text-overflow-by">
										{{ sectionForm.bugetValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段建安工程概算价："
											  prop="estimatePriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.estimatePriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="招标控制价："
											  prop="controlPriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.controlPriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="预备金/暂列金："
											  prop="reservePriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.reservePriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="合同价："
											  prop="contractPriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.contractPriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="中标价："
											  prop="bidPriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.bidPriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段(包)内容建设与规模："
											  prop="content">
									<div class="word-break">{{ sectionForm.content || "暂无" }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="备注说明："
											  prop="remark">
									<div class="word-break">{{ sectionForm.remark || "暂无" }}</div>
								</el-form-item>
							</el-col>
							<!-- <el-col :span="24">
								<el-form-item label="关联单位："
											  prop="relateUnitsName">
									<div>{{ sectionForm.relateUnitsName || "暂无" }}</div>
								</el-form-item>
							</el-col> -->
						</el-row>
					</el-form>
				</el-collapse-item>
				<el-collapse-item title="#2 标段关联单位"
								  name="2">
					<el-form ref="form"
							 label-width="200px">
						<el-col :span="24"
								class="custom-row-section">
							<template v-for="item in unitList">
								<el-row :gutter="10"
										:key="item.id">
									<el-col :span="12">
										<el-form-item :label="item.limitName + '：'">
											{{ item.tenantName }}
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item :label="item.limitName + '负责人：'">
											{{ item.adminUsername }}
										</el-form-item>
									</el-col>
									<el-col :span="24">
										<el-form-item :label="item.limitName + '工程师：'">
											{{ item.userNames }}
										</el-form-item>
									</el-col>
								</el-row>
							</template>
						</el-col>
					</el-form>
				</el-collapse-item>
			</el-collapse>
		</el-row>
	</el-dialog>
</template>
<script>
import * as API from 'api/approval/approval'
export default {
	components: {},
	props: {
		sectionId: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			dialogObj: {
				show: false,
			},
			sectionForm: {},
			activeName: ['1', '2'],
			unitList: [],
		}
	},
	created() {
		// 获取标段信息
		this.getSingleSection()
	},
	methods: {
		// 获取标段信息
		getSingleSection() {
			let params = {
				packId: this.sectionId,
			}
			API.getSectionInfo(params).then((res) => {
				this.sectionForm = res.data
				this.unitList = res.data.busObsCompanies
			})
		},
	},
	watch: {
		sectionId: {
			handler(val, oldval) {
				if (val) {
					this.getSingleSection()
				}
			},
		},
	},
}
</script>
<style lang='less' scoped>
.sectionLook-padding {
	padding: 0 20px;
	.custom-row-section {
		.el-row + .el-row {
			border-top: 2px dashed #58a3f7;
		}
		.el-form-item {
			margin-bottom: 0px !important;
		}
		.el-row {
			margin: 10px 0;
		}
	}
}
</style>
