/**
 * 描述：审计管理 和 审计任务
 * 录入人： 张磊
 */
import request from '../request.js'

/**
 * 描述：获取列表
 * author：崔北超
 * 
 */
export const selectBusTaskAll = function (data) {
    return request({
        url: 'busTask/selectBusTaskAll',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述：任务受理
 * author：崔北超
 * 参数： 
 {
    id： "",
    unitId: "",
    source:""
 }
 */

export const taskAcceptance = function (data) {
    return request({
        url: 'busTask/taskAcceptance',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述：添加负责人
 * author：崔北超
 * 参数： 
 {
    id： "",
    taskPrincipal: "",
    constract: "",
    description: "",
 }
 */
export const addPersonInCharge = function (data) {
    return request({
        url: 'busTask/addPersonInCharge',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述：退审
 * author：崔北超
 * 参数： 
 {
    id： "",
    remark: "",
    source:""
 }
 */
export const taskWithdrawal = function (data) {
    return request({
        url: 'busTask/taskWithdrawal',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述：委托协审
 * author：崔北超
 * 参数： 
 {
    id： "",
    remark: "",
    assitantUnit: ""
 }
 */
export const entrustmentExamination = function (data) {
    return request({
        url: 'busTask/entrustmentExamination',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述：委托任务信息
 * author：崔北超
 * 参数： 
 {
    packId： "",
    statusSign "",
 }
 */
export const selectEntrustInfo = function (data) {
    return request({
        url: 'busTask/selectEntrustInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述：获取标签
 * author：崔北超
 * 参数： 
 {
    objectId: '',
    applys: '',
    busUnit: '',
 }
 */
export const getObjectTags = function (data) {
    return request({
        url: 'operateTagObject/getObjectTags',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述：标签保存
 * author：崔北超
 * 参数： 
 {
    objectId: '',
    applys: '',
    busUnit: '',
 }
 */
export const saveObjectTags = function (data) {
    return request({
        url: 'operateTagObject/saveObjectTags',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述：标签搜索数据
 * author：崔北超
 * 参数： 
 {
    applys: '',
 }
 */
export const selectTagListForBox = function (data) {
    return request({
        url: 'operateTag/selectTagListForBox',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述： 置顶操作接口
 * author：崔北超
 * 参数： 
 {
    applys: '',
 }
 */
export const topOperation = function (data) {
    return request({
        url: '/busTask/topOperation',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述： 置顶任务总数
 * author：崔北超
 * 参数： 
 {
    applys: '',
 }
 */
export const getSortSum = function (data) {
    return request({
        url: '/busTask/getSortSum',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
 * 描述： 审计管理--业主委托协审--进入项目
 * author：崔北超
 * 参数： 
 {
    applys: '',
 }
 */
export const directEntrustmentIntoProject = function (data) {
    return request({
        url: 'busTask/directEntrustmentIntoProject',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}
