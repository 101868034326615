<!--
    描述：公共页面 --- 项目信息页面(查看)
    时间：2021-04-12
    Created by 王帅
-->
<template>
	<div class="project-info-main-look content-layout">
		<div class="auditArea"
			 v-show="auditVisble">
			<el-button size="mini"
					   type="primary"
					   v-show="this.$route.query.type == 'audit'"
					   @click="auditShow"
					   class="audBtn1">审核</el-button>
			<el-button size="mini"
					   v-if="routeQuery == 'audit'"
					   @click="backApproval"
					   class="audBtn2">取消</el-button>
		</div>
		<el-card v-for="(item, index) in cardShowList"
				 :key="'card' + index"
				 class="info-card">
			<!-- 标题 -->
			<div slot="header"
				 class="info-card-header"
				 @click="item.showContent = !item.showContent">
				<span class="card-header-icon"><i :class="
                            item.showContent
                                ? 'el-icon-caret-top'
                                : 'el-icon-caret-bottom'
                        "></i></span>
				<span>{{ `#${index + 1} ${item.title}` }}</span>
			</div>

			<!-- 内容 -->
			<div class="info-content pdt10"
				 v-show="item.showContent">
				<!-- 项目信息 -->
				<template v-if="item.attr === 'baseInfo'">
					<el-form ref="projectForm"
							 :model="projectInfo"
							 size="small"
							 label-width="200px">
						<el-row>
							<el-col :span="12">
								<el-form-item label="项目名称："
											  prop="sname">
									<div class="text-overflow-by">
										{{ projectInfo.sname || '暂无' }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目编号："
											  prop="proNo">
									<div class="text-overflow-by">
										{{ projectInfo.proNo || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目类型："
											  prop="proType">
									<div class="text-overflow-by">
										{{ projectInfo.newProTypeName || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="建设类型：">
									<div class="text-overflow-by">{{ projectInfo.constructionTypeName  }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="建设单位：">
									<div class="text-overflow-by">{{ projectInfo.constructionUnitName  }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item :label="projectInfo.hasSerial ? '建设任务书编号：' : '无任务书编号原因：'"
											  prop="hasSerial">
									<div class="text-overflow-by"
										 style="word-break: break-all">
										{{ projectInfo.hasSerial ? projectInfo.assignNo : projectInfo.reason }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目归属区域："
											  prop="location">
									<div class="text-overflow-by">{{ projectInfo.area  }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目位置："
											  prop="address">
									<el-button v-if="$route.path == '/projectInfoLook'"
											   type="text"
											   icon="el-icon-view"
											   class="mr10"
											   @click="viewMap">查看地图</el-button>
									<span> {{ projectInfo.address || '暂无' }}</span>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目年份："
											  prop="proYear">
									<div class="text-overflow-by">
										{{ projectInfo.proYear || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目造价区间："
											  prop="intervalPrice">
									<div class="text-overflow-by">
										{{ zjDeal(projectInfo.intervalPrice) || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="计划开工时间："
											  prop="planStartTime">
									<div class="text-overflow-by">
										{{ projectInfo.planStartTime && projectInfo.planStartTime.length > 10 ? projectInfo.planStartTime.substr(0, 10) : "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="计划竣工时间："
											  prop="planEndTime">
									<div class="text-overflow-by">
										{{ projectInfo.planEndTime && projectInfo.planEndTime.length > 10 ? projectInfo.planEndTime.substr(0, 10) : "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="争取开工年份："
											  prop="planEndTime">
									<div class="text-overflow-by">
										{{ projectInfo.striveStartDate +'年' || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="建设规模及内容："
											  prop="projectSurvey">
									<div class="text-overflow-by">
										{{ projectInfo.projectSurvey || '暂无' }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="长度：">
									<div class="text-overflow-by"
										 style="word-break: break-all">
										{{ projectInfo.lengthSign ? projectInfo.length + ' 米' :  '暂无此内容' }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="建筑面积：">
									<div class="text-overflow-by"
										 style="word-break: break-all">
										{{ projectInfo.floorageSign ? projectInfo.floorage + ' 平方米' : '暂无此内容' }}
									</div>
								</el-form-item>
							</el-col>
							<el-col>
								<el-form-item label="项目封面：">
									<myUpload list-type="list"
											  ref="coverUpload"
											  :createUser="userInfo.sname"
											  tableName="bus_project"
											  assortment="cover_file"
											  width="auto"
											  height="auto"
											  :maxNum="1"
											  :aid="projectInfo.id"
											  :isDisabled="true"
											  mode="view"
											  uploadType="99"></myUpload>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</template>

				<!-- 项目费用 -->
				<template v-if="item.attr === 'projectMoney'">
					<el-form ref="moneyForm"
							 :model="moneyForm"
							 size="small"
							 label-width="200px">
						<el-row>
							<el-col :span="24">
								<el-form-item label-width="110px">
									项目投资（无费用，可填写0）
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目总投资：">
									<div class="text-overflow-by">
										{{ projectInfo.proTotalValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<template v-for="(item, index) in moneyForm.feeTr">
								<el-col :span="12"
										:key="'feeTr' + index">
									<el-form-item :label="item.feeDictName + '：'">
										<el-col :span="12">
											<div class="text-overflow-by">
												{{ item.feeValue + " 万元" }}
											</div>
										</el-col>
										<el-col :span="12">
											<span v-if="item.percent">
												占比：{{ item.percent }} %
											</span>
										</el-col>
									</el-form-item>
								</el-col>
							</template>
						</el-row>
						<el-row>
							<el-col :span="24"
									class="border-top">
								<el-form-item label-width="110px">
									项目资金来源（无费用，可填写0）
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目总来源资金：">
									<div class="text-overflow-by">
										{{ projectInfo.proTotalInvestment + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<template v-for="(item, index) in moneyForm.feeLy">
								<el-col :span="12"
										:key="'feeLy' + index">
									<el-form-item :label="item.feeDictName + '：'">
										<el-col :span="12">
											<div class="text-overflow-by">
												{{ item.feeValue + " 万元" }}
											</div>
										</el-col>
										<el-col :span="12">
											<span v-if="item.percent">
												占比：{{ item.percent }} %
											</span>
										</el-col>
									</el-form-item>
								</el-col>
							</template>
						</el-row>
					</el-form>
				</template>

				<!-- 业主单位 -->
				<template v-if="item.attr === 'owner'">
					<vxe-table align="center"
							   border
							   :data="ownerInfo"
							   show-header-overflow
							   show-overflow
							   highlight-current-row
							   highlight-hover-row>
						<vxe-table-column field="limitName"
										  title="单位类型"></vxe-table-column>
						<vxe-table-column field="sname"
										  title="单位名称"
										  header-align="center"
										  align="left">
							<template #default="{ row }">
								<el-tooltip effect="dark"
											:content="row.sname"
											placement="top">
									<span class="vxe-cell-label">{{ row.shortName }}</span>
								</el-tooltip>
							</template>
						</vxe-table-column>
						<vxe-table-column field="leaderName"
										  title="项目人员"></vxe-table-column>
                        <vxe-table-column field="phone"
										  title="联系方式"></vxe-table-column>
                        <vxe-table-column field="postName"
										  title="职务"></vxe-table-column>
						<vxe-table-column field="roleName"
										  title="项目角色"></vxe-table-column>
					</vxe-table>
				</template>

				<!-- 项目资料 -->
				<template v-if="item.attr === 'projectData'">
					<div class="text-right btnGroups mb10">
						<el-select placeholder="请选择"
								   v-model="curLevel"
								   @change="changeLevel"
								   size="small">
							<el-option v-for="n in maxLevel"
									   :key="'level' + n"
									   :value="n"
									   :label="`展开到${n}级`"></el-option>
						</el-select>
					</div>
					<vxe-table ref="dataTable"
							   :data="dataTableObj.tableData"
							   resizable
							   border
							   :loading="dataTableObj.loading"
							   row-id="templateId"
							   :tree-config="{children: 'children', expandAll: true}"
							   show-overflow
							   show-header-overflow>
						<vxe-table-column title="序号"
										  align="left"
										  width="80"
										  type="seq">
						</vxe-table-column>
						<vxe-table-column title="资料清单"
										  field="sname"
										  align="left"
										  tree-node
										  min-width="300">
							<template #default="{ row }">
								<el-tooltip effect="dark"
											:content="row.remark"
											:disabled="row.level == 0 || row.level == 1"
											placement="top">
									<span>{{ row.sname }}</span>
								</el-tooltip>
							</template>
						</vxe-table-column>
						<vxe-table-column title="附件"
										  field="fileName"
										  align="center"
										  min-width="200">
							<template #default="{ row }">
								<span class="vxe-table--cell blue-text"
									  v-if="row.fileList.length > 0"
									  @click="viewData(row)">
									查看（共{{ row.fileList.length }} 个文件）
								</span>
								<span v-else>-</span>
							</template>
						</vxe-table-column>
						<vxe-table-column title="备注"
										  align="center">
							<template #default="{ row }">
								{{ row.noSuchContent ? '暂无此内容' : '-' }}
							</template>
						</vxe-table-column>
					</vxe-table>
				</template>

				<!-- 标段信息 -->
				<template v-if="item.attr === 'sectionInfo'">
					<vxe-table ref="sectionTable"
							   :data="sectionTableObj.tableData"
							   :loading="sectionTableObj.loading"
							   resizable
							   border
							   show-overflow
							   show-header-overflow>
						<vxe-table-column title="序号"
										  align="center"
										  width="70"
										  type="seq">
						</vxe-table-column>
						<vxe-table-column title="标段编号"
										  field="packNo"
										  align="center"
										  width="180">
							<template #default="{ row, rowIndex }">
								<div>
									<template v-if="getSectionStatus(row.status) !== ''">
										<el-tooltip :content="row.remark"
													placement="right">
											<svg-icon class="section-icon"
													  :icon-class="getSectionStatus(row.status)"></svg-icon>
										</el-tooltip>
									</template>
									{{ row.packNo }}
								</div>
							</template>
						</vxe-table-column>
						<vxe-table-column title="标段名称"
										  field="sname"
										  header-align="center"
										  align="left"
										  min-width="180">
							<template #default="{ row, rowIndex }">
								<span class="text-color"
									  @click="doViewSection(row)">
									{{ row.sname }}
								</span>
							</template>
						</vxe-table-column>
						<vxe-table-column title="标段负责人"
										  field="packPrincipalName"
										  align="center"
										  width="120">
						</vxe-table-column>
						<vxe-table-column title="标段类型"
										  field="packTypeName"
										  align="center"
										  width="120">
						</vxe-table-column>
						<vxe-table-column title="合同工期"
										  field="duration"
										  align="center"
										  width="120">
						</vxe-table-column>
						<vxe-table-column title="标段预算金额（万元）"
										  field="buget"
										  header-align="center"
										  align="right"
										  width="180">
						</vxe-table-column>
						<vxe-table-column title="标段内容建设与规模"
										  field="content"
										  header-align="center"
										  align="left"
										  min-width="200">
						</vxe-table-column>
					</vxe-table>
				</template>

				<!-- 单个的标段信息 -->
				<template v-if="item.attr === 'singleSectionInfo'">
					<el-form ref="sectionForm"
							 :model="sectionForm"
							 size="small"
							 label-width="200px">
						<el-row>
							<el-col :span="12">
								<el-form-item label="标段编号："
											  prop="packNo">
									<div class="text-overflow-by">
										{{ sectionForm.packNo || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段名称："
											  prop="sname">
									<div class="text-overflow-by">
										{{ sectionForm.sname || '暂无' }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段(包)类型："
											  prop="packType">
									<div class="text-overflow-by">
										{{ sectionForm.packTypeName || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="合同工期："
											  prop="duration">
									<div class="text-overflow-by">
										{{ sectionForm.duration + " 天" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="项目标段负责人："
											  prop="packPrincipal">
									<div class="text-overflow-by">
										{{ sectionForm.packPrincipalName || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段负责人联系方式："
											  prop="constract">
									<div class="text-overflow-by">
										{{ sectionForm.constract || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段预算金额："
											  prop="buget">
									<div class="text-overflow-by">
										{{ sectionForm.bugetValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段建安工程概算价："
											  prop="estimatePriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.estimatePriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="招标控制价："
											  prop="controlPriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.controlPriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="预备金/暂列金："
											  prop="reservePriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.reservePriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="合同价："
											  prop="contractPriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.contractPriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="中标价："
											  prop="bidPriceValue">
									<div class="text-overflow-by">
										{{ sectionForm.bidPriceValue + " 万元" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段(包)内容建设与规模："
											  prop="content">
									<div class="word-break">{{ sectionForm.content || "暂无" }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="备注说明："
											  prop="remark">
									<div class="word-break">{{ sectionForm.remark || "暂无" }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="关联单位："
											  prop="relateUnitsName">
									<div>{{ sectionForm.relateUnitsName || "暂无" }}</div>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<vxe-table ref="dataTableSingle"
							   :data="sectionTableObj.fileData"
							   :loading="sectionTableObj.fileLoaing"
							   resizable
							   border
							   class="hidden"
							   show-overflow
							   show-header-overflow>
						<vxe-table-column title="序号"
										  align="center"
										  width="70"
										  type="seq">
						</vxe-table-column>
						<vxe-table-column title="资料类型"
										  field="dataType"
										  align="center"
										  width="220">
							<template #default="{ row, rowIndex }">
								<Treeselect :options="projectDict.sjzllx"
											openOnFocus
											:normalizer="typeNormalizer"
											:disabled="row.id && row.id !== ''"
											appendToBody
											:default-expand-level="1"
											placeholder="请选择资料类型..."
											v-model="row.source"></Treeselect>
							</template>
						</vxe-table-column>
						<vxe-table-column title="附件"
										  field="fileName"
										  align="center"
										  min-width="200">
							<template v-slot="{ row }">
								<FileName :fileName="row.fileName"
										  :filePath="row.filePath"
										  :fileSize="row.fileSize"></FileName>
							</template>
						</vxe-table-column>
						<vxe-table-column title="文件来源"
										  field="fileSource"
										  header-align="center"
										  align="left"
										  width="200">
						</vxe-table-column>
						<vxe-table-column title="上传时间"
										  field="createDate"
										  align="center"
										  width="180">
						</vxe-table-column>
					</vxe-table>
				</template>

				<!-- 报审信息 -->
				<template v-if="item.attr === 'reportInfo'">
					<el-form ref="reportForm"
							 :model="reportForm"
							 label-width="200px"
							 size="small">
						<el-row>
							<el-col :span="12">
								<el-form-item label="审计任务类型：">
									<div class="text-overflow-by">
										{{ reportForm.taskTypeName }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="委托方式：">
									<div class="text-overflow-by">
										{{
                                            reportForm.entrustType === 1
                                                ? "委托审计机关"
                                                : "自行委托"
                                        }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item :label="'委托' + reportForm.unitTypeName + '：'">
									<div class="text-overflow-by">
										{{ reportForm.unitName }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="审计单位类型：">
									<div class="text-overflow-by">
										{{ reportForm.unitTypeName }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12"
									v-if="reportForm.taskType === '1006004002'">
								<el-form-item label="结算报审价：">
									<div class="text-overflow-by">
										{{ reportForm.auditPrice || 0 }}万元
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12"
									v-if="reportForm.entrustType === 0">
								<el-form-item label="协审单位确定方式：">
									<div class="text-overflow-by">
										{{ reportForm.confiymTypeName }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="审计要求完成时间：">
									<div class="text-overflow-by"
										 v-if="reportForm.finishTime && reportForm.finishTime != ''">
										{{ reportForm.finishTime.slice(0,10) || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="标段状态：">
									<div class="text-overflow-by">
										{{ dealStatus(reportForm.status) }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item :label="dealTitle(reportForm.status)">
									<div class="text-overflow-by"
										 v-if="reportForm.completeTime && reportForm.completeTime != ''">
										{{ reportForm.completeTime.slice(0,10) || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12"
									v-if="reportForm.taskType === '1006004002'">
								<el-form-item label="是否有甩项：">
									<div class="text-overflow-by">
										{{ reportForm.hasJilt ? '是' : '否' }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12"
									v-if="reportForm.taskType === '1006004002' && reportForm.hasJilt == 1">
								<el-form-item label="甩项金额：">
									<div class="text-overflow-by">
										{{ reportForm.jiltAmount + '元' }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="备注：">
									<div class="word-break">{{ reportForm.remark || "暂无" }}</div>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</template>

				<!-- 审核信息 -->
				<template v-if="item.attr === 'checkInfo'">
					<vxe-table ref="checkTable"
							   :data="checkTableObj.tableData"
							   resizable
							   border
							   show-overflow
							   show-header-overflow>
						<vxe-table-column title="序号"
										  align="center"
										  width="70"
										  type="seq">
						</vxe-table-column>
						<vxe-table-column title="审批人"
										  field="sectionSname"
										  align="center">
						</vxe-table-column>
						<vxe-table-column title="审批状态"
										  field="sectionState"
										  align="center">
						</vxe-table-column>
						<vxe-table-column title="审批时间"
										  field="sectionDate"
										  align="center">
						</vxe-table-column>
						<vxe-table-column title="审批结果"
										  field="sectionResult"
										  align="center">
						</vxe-table-column>
						<vxe-table-column title="审批意见"
										  field="sectionRemark"
										  align="center">
						</vxe-table-column>
					</vxe-table>
				</template>

				<!-- 审计单位 -->
				<template v-if="item.attr === 'auditUnitInfo'">
					<el-form ref="auditUnitForm"
							 :model="auditUnitForm"
							 size="small"
							 label-width="200px">
						<el-row>
							<el-col :span="12">
								<el-form-item label="审计单位：">
									<div class="text-overflow-by">{{ auditUnitForm.unitName || "暂无" }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="审计单位地址：">
									<div class="text-overflow-by">{{ auditUnitForm.address || "暂无" }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="审计单位组织机构代码：">
									<div class="text-overflow-by">{{ auditUnitForm.creditCode || "暂无" }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="审计单位类别：">
									<div class="text-overflow-by">{{ auditUnitForm.unitTypeName || "暂无" }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="审计单位联系人：">
									<div class="text-overflow-by">{{ auditUnitForm.unitLinker || "暂无" }}</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="联系方式：">
									<div class="text-overflow-by">{{ auditUnitForm.unitContract || "暂无" }}</div>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</template>

				<!-- 协审单位 -->
				<template v-if="item.attr === 'assistantInfo'">
					<el-form ref="assistantForm"
							 :model="assistantForm"
							 size="small"
							 label-width="200px">
						<el-row>
							<el-col :span="12">
								<el-form-item label="协审单位：">
									<div class="text-overflow-by">
										{{ assistantForm.unitName || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="协审单位地址：">
									<div class="text-overflow-by">
										{{ assistantForm.address || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="协审单位组织机构代码：">
									<div class="text-overflow-by">
										{{ assistantForm.creditCode || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="协审单位类别：">
									<div class="text-overflow-by">
										{{ assistantForm.unitTypeName || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="协审单位联系人：">
									<div class="text-overflow-by">
										{{ assistantForm.unitLinker || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="联系方式：">
									<div class="text-overflow-by">
										{{ assistantForm.unitContract || "暂无" }}
									</div>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</template>

				<!-- 委托信息 -->
				<template v-if="item.attr === 'entrustInfo'">
					<div v-for="(item, index) in entrustList"
						 :key="'entrustList' + index"
						 class="entrust-box">
						<p class="text-overflow-by">
							{{ dealSubType(item.subType) }}：
							<span class="blue">
								{{ item.unitName }}
							</span>
						</p>
						<el-row>
							<el-col :span="6"
									class="text-overflow-by">
								<span v-if="item.subType == '1'">委托审计任务编号：</span>
								<span v-else-if="item.subType == '2'">审计单位任务编号：</span>
								<span v-else-if="item.subType == '3'">协审单位任务编号：</span>
								{{ item.orderNo }}
							</el-col>
							<el-col :span="6"
									class="text-overflow-by">
								{{ item.subType == "1" ? "报审日期" : "受理日期" }}：
								{{
                                    item.subType == "1"
                                        ? item.taskEntrustDate
                                        : item.taskAcceptDate
                                }}
							</el-col>
							<el-col :span="6"
									class="text-overflow-by"
									v-if="item.subType != '3'">
								{{
                                    item.subType == "1" ? "要求完成时间" : "委托协审日期"
                                }}：
								{{
                                    item.subType == "1"
                                        ? item.finshDate
                                        : item.entrustTime
                                }}
							</el-col>
							<el-col :span="6"
									class="text-overflow-by">
								审计任务类型： {{ item.auditTypeName }}
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="6"
									class="text-overflow-by"
									v-if="item.subType == '1' && item.auditTypeName == '结算审计'">
								结算报审价：{{ item.auditPrice }} 万元
							</el-col>
							<el-col :span="6"
									class="text-overflow-by"
									v-if="item.subType == '1'">
								标段状态：{{ dealStatus(item.packStatus, item.auditTypeName) }}
							</el-col>
							<el-col :span="6"
									class="text-overflow-by"
									v-if="item.subType == '1'">
								{{ dealTitle(item.packStatus, item.auditTypeName)}}
								<span
									  v-if="item.completeTime && item.completeTime != ''">{{ item.completeTime.slice(0, 10) }}</span>
							</el-col>
							<el-col :span="6"
									class="text-overflow-by"></el-col>
							<el-col :span="6"
									class="text-overflow-by">
								<span v-if="item.subType == '1'">建设单位项目负责人：</span>
								<span v-else-if="item.subType == '2'">审计单位项目负责人：</span>
								<span v-else-if="item.subType == '3'">协审单位项目负责人：</span>
								{{ item.taskPrincipal }}
							</el-col>
							<el-col :span="6"
									class="text-overflow-by">
								项目负责人联系方式： {{ item.constract }}
							</el-col>
							<el-col :span="12"
									class="text-overflow-by">
								<div class="flex">
									<p>备注：</p>
									<p class="text-overflow-by">
										{{ item.remark }}
									</p>
								</div>
							</el-col>
							<el-col :span="24"
									class="text-overflow-by flex">
								附件：
								<myUpload v-if="item.id"
										  ref="fileUpload"
										  tableName="bus_task"
										  assortment="test"
										  listType="text"
										  width="200px"
										  height="100px"
										  mode="view"
										  :isDisabled="true"
										  :aid="item.id"></myUpload>
							</el-col>
						</el-row>
					</div>
				</template>

				<!-- 操作日志 -->
				<template v-if="item.attr === 'logData'">
					<vxe-table ref="logTable"
							   :data="logData"
							   resizable
							   border
							   align="center"
							   show-overflow
							   show-header-overflow>
						<vxe-table-column title="操作时间"></vxe-table-column>
						<vxe-table-column title="操作人员"></vxe-table-column>
						<vxe-table-column title="日志详情"></vxe-table-column>
						<vxe-table-column title="说明"></vxe-table-column>
					</vxe-table>
				</template>
			</div>
		</el-card>

		<!-- 弹窗管理 -->
		<el-dialog :visible.sync="dialogObj.visible"
				   :title="dialogObj.title"
				   :width="dialogObj.width"
				   center
				   append-to-body
				   @closed="doCancel">
			<!-- 查看资料弹窗 -->
			<template v-if="dialogObj.type === 'file'">
				<p class="mb10"
				   v-if="dialogObj.mode == 'add'">请将需要上传的文件在此窗口一次性操作完毕，窗口关闭后之前上传的文件将会被覆盖</p>
				<myUpload list-type="table"
						  ref="fileUpload"
						  :createUser="userInfo.sname"
						  tableName="bus_project"
						  assortment="project_file"
						  :isDisabled="dialogObj.mode == 'view'"
						  :mode="dialogObj.mode"
						  :uploadType="dialogObj.mode == 'view' ? '' : '0'"></myUpload>
			</template>

			<!-- 查看标段弹窗 -->
			<template v-if="dialogObj.type === 'section'">
				<el-form ref="sectionForm"
						 :model="sectionForm"
						 size="small"
						 label-width="200px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="标段编号："
										  prop="packNo">
								<div class="text-overflow-by">
									{{ sectionForm.packNo || "暂无" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="标段名称："
										  prop="sname">
								<div class="text-overflow-by">
									{{ sectionForm.sname || '暂无' }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="标段(包)类型："
										  prop="packType">
								<div class="text-overflow-by">
									{{ sectionForm.packTypeName || "暂无" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="合同工期："
										  prop="duration">
								<div class="text-overflow-by">
									{{ sectionForm.duration + " 天" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="项目标段负责人："
										  prop="packPrincipal">
								<div class="text-overflow-by">
									{{ sectionForm.packPrincipalName || "暂无" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="标段负责人联系方式："
										  prop="constract">
								<div class="text-overflow-by">
									{{ sectionForm.constract || "暂无" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="标段预算金额："
										  prop="buget">
								<div class="text-overflow-by">
									{{ sectionForm.buget + " 万元" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="标段建安工程概算价："
										  prop="estimatePrice">
								<div class="text-overflow-by">
									{{ sectionForm.estimatePrice + " 万元" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="招标控制价："
										  prop="controlPrice">
								<div class="text-overflow-by">
									{{ sectionForm.controlPrice + " 万元" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="预备金/暂列金："
										  prop="reservePrice">
								<div class="text-overflow-by">
									{{ sectionForm.reservePrice + " 万元" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="合同价："
										  prop="contractPrice">
								<div class="text-overflow-by">
									{{ sectionForm.contractPrice + " 万元" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="中标价："
										  prop="bidPrice">
								<div class="text-overflow-by">
									{{ sectionForm.bidPrice + " 万元" }}
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="标段(包)内容建设与规模："
										  prop="content">
								<div>{{ sectionForm.content || "暂无" }}</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="备注说明："
										  prop="remark">
								<div>{{ sectionForm.remark || "暂无" }}</div>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="关联单位："
										  prop="relateUnitsName">
								<div>{{ sectionForm.relateUnitsName || "暂无" }}</div>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</template>

			<div slot="footer">
				<el-button size="small"
						   class="btn"
						   @click="doCancel">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 审核弹窗 -->
		<el-dialog :title="auditObj.title"
				   :visible.sync="auditObj.show"
				   center
				   :before-close="auditCancel"
				   append-to-body
				   :close-on-click-modal="false"
				   :width="auditObj.width">
			<el-form>
				<el-row>
					<el-col :span="12">
						<el-form-item label="审核结果"
									  label-width="150px"
									  required="">
							<el-radio v-model="auditObj.status"
									  label="1001026002">通过</el-radio>
							<el-radio v-model="auditObj.status"
									  label="1001026003">驳回</el-radio>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<p style="margin:12px 0px;color:blue">
							备注：驳回，必须在审核意见内填写驳回理由</p>
					</el-col>
				</el-row>
				<el-form-item label="审核意见"
							  label-width="150px">
					<el-input type="textarea"
							  :autosize="{ minRows: 6 }"
							  :maxlength="200"
							  show-word-limit
							  placeholder="请输入内容"
							  v-model="auditObj.remark">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button class="btn"
						   @click="auditCancel"
						   size="small">取消</el-button>
				<el-button class="btn btn-blue"
						   size="small"
						   @click="auditConfirm"
						   :loading="dialogObj.btnLoading">提交</el-button>
			</div>
		</el-dialog>

		<!-- 查看标段信息 -->
		<SectionLook v-if="sectionShow"
					 ref="SectionLook"
					 :sectionId="sectionId"></SectionLook>

		<!-- 地图定位 -->
		<el-dialog title="地图定位"
				   :visible.sync="locationShow"
				   width="1200px"
				   center
				   top="4vh"
				   :close-on-click-modal="false"
				   :before-close="mapClose"
				   lock-scroll
				   :destory-on-close="true">
			<div style="height: 680px">
				<ProjectAddMap ref="ProjectMapIndex"
							   v-if="locationShow"
							   mode="view"></ProjectAddMap>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { getSubmitAdd } from 'api/approval/approval'
import { mapState } from 'vuex'
import AddressForm from 'components/addressForm/addressForm'
import * as Api from 'api/projectManage/ProjectIndex'
import * as UploadApi from 'api/upload/upload'
import * as AuditApi from 'api/audit/audit'
import * as ApprovalApi from 'api/approval/approval'
import VUE_CONFIG from '../../../vue.config.js'
import TextTooltip from '@/views/Common/TextTooltip'
import SectionLook from '@/views/Common/SectionLook'
import ProjectAddMap from '@/views/ProjectMap/ProjectAddMap'

export default {
	name: 'ProjectInfoLook',
	components: {
		AddressForm,
		TextTooltip,
		SectionLook,
		ProjectAddMap,
	},
	props: {
		noRoute: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			//审核
			auditObj: {
				status: '1001026002',
				remark: '',
				title: '审核',
				show: false,
				width: '800px',
			},
			// 全部的 card 数组
			cardList: [
				{
					title: '项目信息',
					attr: 'baseInfo',
					showContent: true,
				},
				{
					title: '项目费用',
					attr: 'projectMoney',
					showContent: true,
				},
				{
					title: '参建单位信息',
					attr: 'owner',
					showContent: true,
				},
				{
					title: '标段信息',
					attr: 'sectionInfo',
					showContent: true,
				},
				{
					title: '标段信息', // 单个标段
					attr: 'singleSectionInfo',
					showContent: true,
				},
				{
					title: '项目资料',
					attr: 'projectData',
					showContent: true,
				},
				{
					title: '委托审计信息',
					attr: 'reportInfo',
					showContent: true,
				},
				{
					title: '审核信息',
					attr: 'checkInfo',
					showContent: true,
				},
				{
					title: '审计单位信息',
					attr: 'auditUnitInfo',
					showContent: true,
				},
				{
					title: '协审单位信息',
					attr: 'assistantInfo',
					showContent: true,
				},
				{
					title: '委托信息',
					attr: 'entrustInfo',
					showContent: true,
				},
				{
					title: '操作日志',
					attr: 'logData',
					showContent: true,
				},
			],
			logData: [],
			// 项目信息表单
			projectInfo: {
				id: undefined,
				auditId: undefined,
				sname: undefined,
				proNo: undefined,
				buildUnitName: undefined,
				buildPrincipal: undefined,
				constract: undefined,
				area: undefined,
				location: undefined,
				address: undefined,
				proType: undefined,
				proYear: undefined,
				branchUnit: undefined,
				status: undefined,
				intervalPrice: undefined,
				assignNo: undefined,
				buget: undefined,
				control: undefined,
				contract: undefined,
				settle: undefined,
			},
			// 项目费用
			moneyForm: {
				proTotalValue: 0,
				feeTr: [], //项目投入
				feeLy: [], // 项目费用来源
			},
			// 业主单位表单
			ownerInfo: [
				{
					ownerUnit: undefined,
					unitAddress: undefined,
					creditCode: undefined,
					unitType: undefined,
					unitLinker: undefined,
					contract: undefined,
				},
			],
			// 项目资料
			curLevel: 1,
			maxLevel: 1, // 最大level
			dataTableObj: {
				tableData: [], // 表格数据
				currentId: '',
				loading: false,
			},
			// 文件列表
			fileList: [],
			tableName: '', // 文件表名
			fileDialog: {
				visible: false,
				btnLoading: false,
			},
			// 标段信息
			sectionTableObj: {
				loading: false,
				tableData: [], // 表格数据
				fileData: [], // 附件表格数据
				fileList: [], // 附件原始数据
				fileLoaing: false,
			},
			sectionForm: {
				projectId: undefined,
				auditId: undefined,
				packNo: undefined,
				sname: undefined,
				packPrincipal: undefined,
				constract: undefined,
				duration: undefined,
				constructionName: undefined,
				packType: undefined,
				buget: undefined,
				content: undefined,
				remark: undefined,
			},
			// 协审单位信息
			assistantForm: {
				unitName: '', //委托/审计/协审单位
				address: '', //单位地址
				creditCode: '', //统一社会信用代码
				unitTypeName: '', //单位类型名称
				unitContract: '', //联系方式
				unitLinker: '', //联系人
			},
			// 审计单位信息
			auditUnitForm: {
				unitName: '', //委托/审计/协审单位
				address: '', //单位地址
				creditCode: '', //统一社会信用代码
				unitTypeName: '', //单位类型名称
				unitContract: '', //联系方式
				unitLinker: '', //联系人
			},
			// 委托信息
			entrustList: [],
			// 审核信息
			checkTableObj: {
				tableData: [], // 表格数据
			},
			// 报审对象
			reportTableObj: {
				unitList: [],
			},
			reportForm: {
				projectId: undefined, // 项目id
				packId: undefined, // 标段id
				auditId: undefined, // 项目子表id
				tenantId: undefined, // 租户ID
				entrustType: 0, // 委托方式   0：自行委托   1：委托审计中心
				entrustUnit: undefined, // 委托协审单位/委托审计中心
				taskType: undefined, // 审计任务类型
				utilType: undefined, //审计单位类型
				finishTime: undefined, // 完成时间
				remark: undefined, // 备注
				auditPrice: '', //结算报审价
				sponsorUnit: undefined, // 发起单位(委托单位)
				taskPrincipal: undefined, // 项目负责人
				constract: undefined, // 负责人联系方式
				completeTime: '', //计划竣工时间
				status: '', //标段状态  0：未竣工  1：已竣工
				teskId: '',
				utilTypeName: '',
			},

			// 弹窗对象
			dialogObj: {
				visible: false,
				title: '',
				width: '80%',
				type: '',
				btnLoading: false,
			},
			ShowForm: false,
			companyList: [], //公司列表
			typeNormalizer(node) {
				//去掉children=[]的children属性
				if (node.children && !node.children.length) {
					delete node.children
				}
				return {
					id: node.value,
					label: node.sname,
					children: node.children,
				}
			},
			sectionId: '',
			sectionShow: false,
			locationShow: false,
			allLocation: [],
		}
	},
	created() {
		// 查看页面 直接回显项目信息
		if (!this.noRoute) {
			this.getInfoData()
		}

		// 获取报审信息
		if (
			this.$route.query.menu === 'approval' ||
			this.$route.query.menu === 'commission'
		) {
			this.getApprovalReport(this.$route.query.menu)
		}

		// 获取审计信息
		if (this.$route.query.menu === 'auditManage') {
			this.selectEntrustInfo()
		}

		// 获取所有的单位
		this.getCompanyList()
	},
	methods: {
		viewMap() {
			this.locationShow = true
			setTimeout(() => {
				if (this.$refs.ProjectMapIndex) {
					if (this.allLocation.length > 0) {
						this.$refs.ProjectMapIndex.initMap(this.allLocation)
					}
				}
			}, 300)
		},

		mapClose() {
			this.locationShow = false
		},

		backApproval() {
			this.$router.push({ path: '/approval' })
		},

		//审核取消
		auditCancel() {
			this.auditObj.show = false
			this.auditObj.remark = ''
		},

		//审核提交
		auditConfirm() {
			if (
				this.auditObj.status == '1001026003' &&
				this.auditObj.remark == ''
			) {
				this.$message.error('请填写意见')
			} else {
				this.dialogObj.btnLoading = true
				let params = Object.assign({}, this.auditObj)
				params.busId = this.currentRow.busId
				getSubmitAdd(params).then((res) => {
					this.dialogObj.btnLoading = false
					if (res.operationCode != 1 || res.msg == 'faild') {
						this.$message.error(res.data)
						return
					}
					this.$router.push({ path: '/approval' })
				})
			}
		},

		//展示审核
		auditShow() {
			this.auditObj.show = true
		},
		// 信息回显
		getInfoData(id) {
			let aid = id ? id : this.$route.query.projectId
			Api.getProjectInfo({ id: aid,type:'1' })
				.then((res) => {
					// console.log(res);
					this.projectInfo = res.data.projectInfo
					this.ownerInfo = this.$utils.orderBy(res.data.ownerInfo, [
						'limitName',
						'asc',
					])
					if (res.data.projectMap.length > 0) {
						this.allLocation = res.data.projectMap
					}

					this.moneyForm.feeLy = res.data.dxxmzjly
					this.moneyForm.feeTr = res.data.dxxmtr
					this.trMoneyChange()
					this.lyMoneyChange()

					// 地址处理
					if (this.projectInfo.area && this.projectInfo.area !== '') {
						let arr = this.projectInfo.area.split(',')
						this.projectInfo.location = {
							province: {
								value: arr[0],
							},
							city: {
								value: arr[1],
							},
							area: {
								value: arr[2],
							},
						}
					}

					// 回显标段信息
					switch (this.$route.query.menu) {
						case 'project':
							this.getSectionData()
							break
						case undefined:
							if (this.$route.path == '/projectMap') {
								this.getSectionData()
							}
							break
						default:
							this.getSingleSection()
					}

					// 回显资料附件
					this.getDataFiles(res.data.projectInfo.id)
				})
				.catch((err) => {})
		},

		// 根据项目ID 回显标段数据
		getSectionData() {
			Api.getSectionInfo({ projectId: this.projectInfo.id })
				.then((res) => {
					this.sectionTableObj.tableData = res.data
				})
				.catch((err) => {
					this.sectionTableObj.tableData = []
				})
		},

		// 根据项目ID 回显附件
		getDataFiles(aid) {
			Api.getProjectFileData({
				projectId: aid || '',
				tableName: 'bus_project',
				source: 'xxx',
			})
				.then((res) => {
					this.dataTableObj.tableData = res.data
					this.maxLevel =
						res.data[0] && res.data[0].maxLevel
							? parseInt(res.data[0].maxLevel) + 1
							: 1
					this.curLevel = this.maxLevel
				})
				.catch((err) => {
					this.dataTableObj.tableData = []
				})
		},

		// 切换展开层级
		changeLevel(level) {
			// 先关闭所有
			this.$refs.dataTable[0].clearTreeExpand()

			// 找到对应层级的数组
			let arr = this.$utils.filterTree(
				this.dataTableObj.tableData,
				(x) => x.level < level - 1
			)
			this.$refs.dataTable[0].setTreeExpand(arr, true)
		},

		// 查看项目资料
		viewData(row) {
			this.dataTableObj.currentId = row.id
			this.dialogObj.visible = true
			this.dialogObj.title = '查看资料'
			this.dialogObj.width = '80%'
			this.dialogObj.type = 'file'
			this.dialogObj.mode = 'view'

			this.$nextTick(() => {
				this.$refs.fileUpload.fileList = row.fileList
			})
		},

		// 我的审批进入时  直接显示对应标段
		getSingleSection() {
			let params = {}
			params.packId = this.currentRow.packId
			ApprovalApi.getSectionInfo(params)
				.then((res) => {
					this.sectionForm = res.data
					this.getSectionFiles(res.data.id)
				})
				.catch((err) => {})
		},

		// 下载
		downFile(data) {
			window.open(process.env.VUE_APP_UPLOAD_URL + data.filePath)
		},

		// 预览
		viewFiles(data) {
			let uploadUrl = ''
			if (process.env.VUE_APP_UPLOAD_URL == '/uploadApi') {
				uploadUrl = VUE_CONFIG.devServer.proxy['/uploadApi'].target
			} else {
				uploadUrl = process.env.VUE_APP_UPLOAD_URL
			}

			window.open(this.$previewUrl + uploadUrl + data.filePath, '_blank')
		},

		// 标段弹窗内 回显资料
		getSectionFiles(aid) {
			let params = {
				aid: aid,
				tableName: 'bus_package',
				assortment: 'package_file',
				source: 'yyy',
				type: '0',
			}
			UploadApi.getFiles(params)
				.then((res) => {
					this.sectionTableObj.fileData = res.data
				})
				.catch((err) => {
					this.sectionTableObj.fileData = []
				})
		},

		// 根据单位类型查询单位
		getCompanyList() {
			Api.selectAuditUnits({
				unitType: '',
			}).then((res) => {
				this.companyList = res.data
			})
		},

		// 获取标段报审状态 及 原因
		getSectionStatus(status) {
			let icon = ''
			// 0：未提交报审;  1：已提交报审;  2：报审内部审核通过; 3：提交报审被驳回; 4：撤回; 5：提交报审被退回; 6：已受理
			switch (status) {
				case 3:
					icon = 'section-refuse'
					break
				case 4:
					icon = 'section-cancel'
					break
				case 5:
					icon = 'section-return'
					break
				default:
					icon = ''
			}

			return icon
		},

		// 处理label
		dealTitle(status, data) {
			let msg = ''
			if (
				this.reportForm.taskTypeName == '结算审计' ||
				data == '结算审计'
			) {
				status == 0
					? (msg = '计划竣工时间：')
					: (msg = '实际竣工时间：')
			} else {
				// 标段状态  0：未竣工  1：已竣工
				status == 0
					? (msg = '计划开工时间：')
					: (msg = '实际开工时间：')
			}
			return msg
		},

		//处理状态
		dealStatus(data, item) {
			let msg = ''
			if (
				this.reportForm.taskTypeName == '结算审计' ||
				item == '结算审计'
			) {
				// 标段状态  0：未竣工  1：已竣工
				data == 0 ? (msg = '未竣工') : (msg = '已竣工')
			} else {
				// 标段状态  0：未开工  1：已开工
				data == 0 ? (msg = '未开工') : (msg = '已开工')
			}
			return msg
		},

		// 查看标段
		doViewSection(row) {
			this.sectionShow = true
			this.sectionId = row.id
			setTimeout(() => {
				this.$refs.SectionLook.dialogObj.show = true
			}, 200)
			// this.dialogObj.visible = true
			// this.dialogObj.title = '查看标段'
			// this.dialogObj.width = '80%'
			// this.dialogObj.type = 'section'
			// let array = []
			// row.obsMaps.forEach((item) => {
			// 	if (item.isFlag == 1) {
			// 		array.push(item.tenantName + '-' + item.limitName)
			// 	}
			// })
			// let companyName = array.join(',')
			// // 为了能够重置表单
			// this.$nextTick(() => {
			// 	this.sectionForm = Object.assign({}, row)
			// 	this.$set(this.sectionForm, 'relateUnitsName', companyName)
			// 	this.getSectionFiles(row.id)
			// })
		},

		// 报审信息
		getApprovalReport(type) {
			if (this.noRoute) {
				return
			}
			let taskId = ''
			if (this.$route.query.menu == 'commission') {
				taskId = this.currentRow.id
			} else if (this.$route.query.menu == 'approval') {
				taskId = this.currentRow.busId
			}
			Api.busTaskInfo({
				taskId: taskId,
			}).then((res) => {
				this.reportForm = res.data
				if (this.$route.query.menu === 'commission') {
					this.reportForm.taskId = this.currentRow.id
				}
			})
		},

		// 获取委托信息
		selectEntrustInfo() {
			AuditApi.selectEntrustInfo({
				packId: this.currentRow.packId,
				statusSign: '1',
				taskId: this.currentRow.id,
				auditType: this.currentRow.auditType,
			}).then((res) => {
				this.entrustList = res.data
				res.data.forEach((x) => {
					if (x.subType == '2') {
						this.auditUnitForm = x
					} else if (x.subType == '3') {
						this.assistantForm = x
					}
				})
			})
		},

		// 弹窗取消
		doCancel() {
			this.dialogObj.visible = false
			this.dialogObj.title = ''
			this.dialogObj.width = ''
			this.dialogObj.type = ''
			this.dialogObj.btnLoading = false
		},

		// 处理类型
		dealSubType(type) {
			let msg = ''
			switch (type) {
				case '1':
					msg = '建设单位'
					break
				case '2':
					msg = '委托审计单位'
					break
				case '3':
					msg = '委托协审单位'
					break
				default:
					msg = ''
			}
			return msg
		},

		// 项目造价区间
		zjDeal(data) {
			let obj = this.projectDict.xmzjqj.find((x) => x.value == data)
			if (obj) {
				return obj.sname
			}
		},

		// 计算总投资额
		trMoneyChange() {
			let total = this.projectInfo.proTotalValue
			this.moneyForm.feeTr.forEach((x) => {
				let _num = this.$utils.divide(x.feeValue, total)
				x.percent = (_num * 100).toFixed(2)
			})
		},

		// 计算项目总来源资金
		lyMoneyChange() {
			let total = this.projectInfo.proTotalInvestment
			this.moneyForm.feeLy.forEach((x) => {
				let _num = this.$utils.divide(x.feeValue, total)
				x.percent = (_num * 100).toFixed(2)
			})
		},
	},
	computed: {
		//审核按钮可见
		auditVisble() {
			return this.$route.query.menu === 'approval'
		},
		// 码表
		...mapState('dict', {
			projectDict: (state) => state.projectDict,
		}),
		...mapState('user', {
			userInfo: (state) => state.userInfo,
		}),

		// 缓存的当前行对象
		currentRow() {
			return this.$getSessionItem('currentRow')
				? JSON.parse(this.$getSessionItem('currentRow'))
				: {}
		},

		routeQuery() {
			return this.$route.query.type
		},

		// 要展示的 card
		cardShowList() {
			let menu = this.$route.query.menu
			let arr = this.cardList.concat()
			let menuArr = []
			let showMenu = []

			switch (menu) {
				// 项目管理
				case 'project':
					menuArr = [
						'baseInfo',
						'projectMoney',
						'owner',
						'projectData',
						'sectionInfo',
						'logData',
					]
					break
				// 我的审批
				case 'approval':
					menuArr = [
						'baseInfo',
						'owner',
						'singleSectionInfo',
						'projectData',
						'reportInfo',
						'checkInfo',
					]
					break
				// 审计管理
				case 'auditManage':
					if (this.$route.query.active === '0') {
						menuArr = [
							'baseInfo',
							'owner',
							'singleSectionInfo',
							'projectData',
							'entrustInfo',
						]
					} else {
						menuArr = [
							'baseInfo',
							'owner',
							'singleSectionInfo',
							'projectData',
							'entrustInfo',
						]
					}

					break
				case 'commission':
					menuArr = [
						'baseInfo',
						'owner',
						'singleSectionInfo',
						'projectData',
						'reportInfo',
						'checkInfo',
					]
					break
				default:
					if (this.$route.path == '/projectMap') {
						menuArr = [
							'baseInfo',
							'projectMoney',
							'owner',
							'sectionInfo',
						]
					}
			}

			let obj = {}
			arr.map((x) => {
				if (menuArr.includes(x.attr)) {
					// if (menu == 'project' && x.attr == 'sectionInfo') {
					// 	obj = x
					// } else {
					showMenu.push(x)
					// }
				}
			})

			// if (menu == 'project') {
			// 	showMenu.splice(2, 0, obj)
			// }

			// console.log(showMenu)
			return showMenu
		},
	},
	beforeRouteLeave(to, from, next) {
		this.$getSessionItem('currentRow')
			? this.$removeSessionItem('currentRow')
			: ''
		if (
			to.path == '/redirect/auditTask' ||
			to.path == '/redirect/auditManagement' ||
			to.path == '/redirect/approval'
		) {
		} else {
			this.$getSessionItem('activeName')
				? this.$removeSessionItem('activeName')
				: ''
		}
		next()
	},
}
</script>

<style scoped lang="less">
.project-info-main-look {
	/deep/ .el-card__header {
		color: #999;
	}

	/deep/ .el-form-item--small.el-form-item {
		margin-bottom: 5px;
	}

	/deep/ .el-form-item__label {
		color: #999;
	}

	.text-overflow {
		color: #202020;
	}

	.text-color {
		color: #60adf9;
		cursor: pointer;
	}
	.auditArea {
		position: absolute;
		right: 10px;
		top: 65px;
		height: 30px;
		width: 200px;
		background-color: white;
	}
	.audBtn1 {
		position: absolute;
		right: 75px;
		top: 0px;
	}
	.audBtn2 {
		position: absolute;
		right: 5px;
		top: 0px;
	}
	.entrust-box {
		padding: 10px;
		line-height: 30px;
		background-color: #eee;
		margin-bottom: 20px;
		font-size: 14px !important;
		.title {
			margin-bottom: 15px;
		}
	}
}
.btn {
	padding: 0 50px !important;
}
.word-break {
	word-break: break-all !important;
}
.border-top {
	padding-top: 20px;
	margin-top: 20px;
	border-top: 3px dotted #eee;
}
</style>
