/**
 * 描述：项目管理 --- 接口管理
 * 录入人： 王帅
 */
import request from '../request.js'


/**
* 描述：分页查询项目信息
* 接口管理员：李开明
* 参数： 
{
    size: '',          // 每页展示条数
    current: '',       // 当前页
    proYear: '',         // 项目年份
    area: '',         // 项目地区
    buildPrincipal: '',         // 建设单位项目负责人
    status: '',         // 项目状态
    intervalPrice: '',         // 项目造价区间
    assignNo: '',         // 建设任务书编号
    packAudit: '',         // 标段是否报审
}
*/
export const getProjectData = function (data) {
    return request({
        url: 'busProject/selectProjectPage',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：新增或修改项目
* 接口管理员：李开明
* 参数： 
{
    tenantId: '',          // 企业id
    projectInfo: {},       // 项目信息对象
    ownerInfo: {},         // 业主/建设单位信息对象
}
*/
export const saveOrUpdateProject = function (data) {
    return request({
        url: 'busProject/saveOrUpdateBusProject',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：删除项目
* 接口管理员：李开明
* 参数： 
{
    id: '',          // 项目id
}
*/
export const deleteProject = function (data) {
    return request({
        url: 'busProject/deleteBusPrpoject',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：项目信息回显
* 接口管理员：李开明
* 参数： 
{
    id: '',          // 项目ID
}
*/
export const getProjectInfo = function (data) {
    return request({
        url: 'busProject/busProjectEcho',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：项目信息是否可编辑
* 接口管理员：崔北超
* 参数： 
{
    id: '',          // 项目ID
}
*/
export const isEditable = function (data) {
    return request({
        url: 'busProject/isEditable',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：项目置顶功能
* 接口管理员：李开明
* 参数： 
{
    type: '',   // 置顶:top，取消置顶:cancel,置顶上移:up 置顶下移： down
    id: '',          // 项目ID
    moveId: ''  // 移动的数据主键id;只有type是up或者down,需要传该参数
}
*/
export const handleMove = function (data) {
    return request({
        url: 'busProject/tagTool',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述：获取项目资料
* 接口管理员：崔北超
* 参数： 
{
    projectId: '',          // 项目ID(有项目时再传，没有为空)
    tableName: ''   // 表名
}
*/
export const getProjectFileData= function (data) {
    return request({
        url: 'busProjectFile/getFiles',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述：新增或编辑标段
* 接口管理员：李开明
* 参数： 
{
    projectId: '',          // 项目ID
    auditId: '',
    sname: '', // 标段名称
    packType: '', // 标段类型码表
    packPrincipal: '',  // 项目标段负责人
    constract: '',  // 标段负责人联系方式
    buget: '',  // 预算金额
    content: '',  // 标段(包)内容建设与规模
    duration: '',  // 合同工期
    packNo: '',  // 标段编号
}
*/
export const saveOrUpdateSection = function (data) {
    return request({
        url: 'busProject/saveOrUpdateBusPackage',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：项目标段信息回显
* 接口管理员：李开明
* 参数： 
{
    projectId: '',          // 项目ID
}
*/
export const getSectionInfo = function (data) {
    return request({
        url: 'busProject/busPackageEcho',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：删除项目标段
* 接口管理员：李开明
* 参数： 
{
    id: '',          // 标段ID
}
*/
export const deleteSection = function (data) {
    return request({
        url: 'busProject/deleteBusPackage',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述： 项目管理 - 根据单位类型查询单位
* 接口管理员：李开明
* 参数： 
{
    unitType: '', // 单位类型
}
*/
export const selectAuditUnits = function (data) {
    return request({
        url: 'busProject/selectSysTenantsByType',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：查询单位列表数据
* 接口管理员：郑志会
* 参数： 
{
    tenantId: '', //-- --租户ID
    size: 10,
    current: 1,
    unitType: "",  //-- --单位类型（ 码表） 
    unitTypes: [],
    sname: "", //-- -- - 企业名称
}
*/
export const selectPagaUnits = function (data) {
    return request({
        url: 'auditUnits/selectPagaUnits',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：标段提交报审-提交申报
* 接口管理员：郑志会
* 参数： 
{
    projectId: undefined,   // 项目id
    packId: undefined,      // 标段id
    auditId: undefined,     // 项目子表id
    tenantId: undefined,    // 租户ID
    entrustType: 0, // 委托方式   0：自行委托   1：委托审计中心
    entrustUnit: undefined, // 委托协审单位/委托审计中心
    taskType: undefined,    // 审计任务类型
    finishTime: undefined,  // 完成时间
    remark: undefined,      // 备注
    sponsorUnit: undefined, // 发起单位(委托单位)
    taskPrincipal: undefined,   // 项目负责人
    constract: undefined,   // 负责人联系方式
}
*/
export const reportSection = function (data) {
    return request({
        url: 'busPackageAudit/busSubmitAudit',
        method: 'post',
        requestConfig: {
            showLoading: true,
            needTenantId: true
        },
        data,
    })
}

/**
* 描述：获得项目的 当前的 标签信息
* 接口管理员：李开明
* 参数：
{
    objectId: '',   // 当前主键ID
	applys: '1001025001001',
	busUnit: '1001025001',
	createUserid: ''    // 创建人ID
}
*/
export const getObjectTags = function (data) {
    return request({
        url: 'operateTagObject/getObjectTags',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：保存项目的 当前的 标签信息
* 接口管理员：李开明
* 参数：
{
    objectId: this.curRowId,
    objectName: this.curRowName,
    applys: "1001025001001",
    busUnit: "1001025001",
    createUserid: this.userInfo.id,
    createUser: this.userInfo.sname,
    addTags: this.selectedTags,
    deleteTags: [],
}
*/
export const saveObjectTags = function (data) {
    return request({
        url: 'operateTagObject/saveObjectTags',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述： 项目下可以委托标段数据获取
* 接口管理员：崔北超
* 参数：
{
    projectId：'', 项目id
}
*/
export const selectEntrustPackage = function (data) {
    return request({
        url: 'busProject/selectEntrustPackage',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述： 委托--标段详细信息获取
* 接口管理员：崔北超
* 参数：
{
    packId '', 标段id
}
*/
export const selectPackageInfo = function (data) {
    return request({
        url: 'busPackage/selectPackageInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述： 委托--委托历史信息获取
* 接口管理员：崔北超
* 参数：
{
    packId '', 标段id
}
*/
export const entrustHistoryInfo = function (data) {
    return request({
        url: 'busTask/entrustHistoryInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述： 委托--任务数据回显
* 接口管理员：崔北超
* 参数：
{
    id '', 标段id
}
*/
export const busTaskInfo = function (data) {
    return request({
        url: 'busPackageAudit/getApprovalReport',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 我的委托--修改--任务数据回显
* 接口管理员：崔北超
* 参数：
{
    id '', 标段id
}
*/
export const commissionBusTaskInfo = function (data) {
    return request({
        url: '/busTask/busTaskInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 修改—— 审计委托
* 接口管理员：崔北超
* 参数：
{
    packId 标段id
    entrustType 委托方式
    entrustUnit 委托协审单位 / 委托审计中心
    auditPrice 结算报审价
    finishTime 要求完成时间
    status 标段状态
    completeTime 计划 / 实际竣工时间
    remark 备注
    taskId 任务id
    sponsorUnit 发起单位(委托单位)
    tenantId 租户id
    taskPrincipal 单位负责人
    constract 负责人联系方式

}
*/
export const againEntrust = function (data) {
    return request({
        url: 'busTask/againEntrust',
        method: 'post',
        requestConfig: {
            showLoading: true,
            needTenantId: true
        },
        data
    })
}

/**
* 描述： 委托审计 / 协审--委托单位数据查询
* 接口管理员：崔北超
* 参数：
{
    limitName： 主体单位类别 审计单位 / 协审单位
}
*/
export const selectentrustUnits = function (data) {
    return request({
        url: 'busTask/selectentrustUnits',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述： 项目管理 - 根据单位类型查询单位
* 接口管理员：李开明
* 参数：
{
}
*/
export const selectSysTenantsByType = function (data) {
    return request({
        url: '/busProject/selectSysTenantsByType',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述： 项目管理 - 审计--标段任务数
* 接口管理员：崔北超
* 参数：
{
}
*/
export const getPackTaskNum = function (data) {
    return request({
        url: 'busTask/getPackTaskNum',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 资料上传 - 模糊查询单位名称
* 接口管理员：崔北超
* 参数：
{
}
*/
export const selectTenantByName = function (data) {
    return request({
        url: '/busProject/selectTenantByName',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 获取当前登录人的公司和负责人信息
* 接口管理员：李开明
* 参数：
{
}
*/
export const selectTenantInformation = function (data) {
    return request({
        url: '/busProject/selectTenantInformation',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 委托报审资料保存
* 接口管理员：崔北超
* 参数：
{
}
*/
export const saveFiles = function (data) {
    return request({
        url: '/busAuditFile/saveFiles',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 我的委托--修改--审计资料修改获取
* 接口管理员：崔北超
* 参数：
{
}
*/
export const getTaskFileListByUpdate = function (data) {
    return request({
        url: '/busAuditFile/getTaskFileListByUpdate',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 委托报审资料回显
* 接口管理员：崔北超
* 参数：
{
}
*/
export const getReportFiles = function (data) {
    return request({
        url: '/busAuditFile/getFiles',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 获取业主建设单位任务id
* 接口管理员：崔北超
* 参数：
{
}
*/
export const getOwnerTaskId = function (data) {
    return request({
        url: '/busTask/getOwnerTaskId',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 审批时内容详情回显
* 接口管理员：崔北超
* 参数：
{
}
*/
export const getCloseItemByTaskId = function (data) {
    return request({
        url: '/taskAssistant/getCloseItemByTaskId',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 标段--校验标段是否可以修改
* 接口管理员：崔北超
* 参数：
{
}
*/
export const checkIsNotUpdateByPackage = function (data) {
    return request({
        url: '/busPackage/checkIsNotUpdateByPackage',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 进入项目
* 接口管理员：崔北超
*/
export const getAuditWorkPower = function (data) {
    return request({
        url: '/sysStation/getAuditWorkPower',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述: 形象进度--左侧菜单获取
 * 接口管理员: 郑志会
 */
export const getAuditscheduleInfos = function (data) {
    return request({
        url: '/auditschedule/getAuditscheduleInfos',
        method: 'post',
        data
    })
}

/**
 * 描述: 形象进度 - 获取修改历史记录
 * 接口管理员: 郑志会
 */
export const getStoryInfo = function (data) {
    return request({
        url: '/auditschedulerecord/getStoryInfo',
        method: 'post',
        data
    })
}

/**
 * 描述: 项目资料 - 修改项目资料是否有此内容
 * 接口管理员: 郑志会
 */
export const updateProjectFileNodeState = function (data) {
    return request({
        url: '/busProjectFile/updateProjectFileNodeState',
        method: 'post',
        data
    })
}

/**
 * 描述: 项目资料 - 修改项目资料是否有此内容
 * 接口管理员: 郑志会
 */
export const delectObsUnitOrUser = function (data) {
    return request({
        url: '/busProject/delectObsUnitOrUser',
        method: 'post',
        data
    })
}