<!--
 * @Author: zl
 * @Date: 2022-07-13 10:57:12
 * @LastEditTime: 2022-09-06 17:26:22
 * @LastEditors: zl
 * @Description: 项目位置 新增
-->
<template>
	<div class="ProjectMapIndex-layout">
		<div class="s-result"
			 v-if="mode != 'view'">
			<el-row>
				<el-col :span="17">
					<el-input v-model="searchName"
							  size="small"
							  placeholder="请选择输入/搜索"
							  id="searchInput"></el-input>
				</el-col>
				<el-col :span="4"
						:offset="1">
					<el-button type="primary"
							   size="small"
							   @click="clearAll('marker')">清除覆盖物</el-button>
				</el-col>
			</el-row>
		</div>
		<div id="mapbox"></div>
		<div id="geolocationIcon"
			 class="geolocationIcon"
			 @click="geolocationIcon"
			 title="回到初始位置"></div>
	</div>
</template>

<script>
export default {
	props: {
		mode: {
			type: String,
			default: 'add',
		},
	},
	components: {},
	data() {
		return {
			map: null,
			geo: null,
			marker: null,
			infoWindow: null,
			clickPoint: {},
			zoom: 15,
			searchName: '',
			polygonBh: null,
			polygonYh: null,
			polygonFd: null,
			polygonFd1: null,
			bhColor: {
				strokeColor: 'blue',
				strokeWeight: 3,
				fillColor: '#e7e1a5',
				fillOpacity: 0.4,
				strokeStyle: 'dashed',
			},
			yhColor: {
				strokeColor: 'blue',
				strokeWeight: 3,
				fillColor: '#9ce9f1',
				fillOpacity: 0.4,
				strokeStyle: 'dashed',
			},
			fdColor: {
				strokeColor: 'blue',
				strokeWeight: 3,
				fillColor: '#93c5a0',
				fillOpacity: 0.4,
				strokeStyle: 'dashed',
			},
			fdColor1: {
				strokeColor: 'blue',
				strokeWeight: 3,
				fillColor: '#93c5a0',
				fillOpacity: 0.4,
				strokeStyle: 'dashed',
			},
			overlays: [],
			customStyle: {
				strokeColor: 'red', //边线颜色。
				fillColor: 'red', //填充颜色。当参数为空时，圆形将没有填充效果。
				strokeWeight: 3, //边线的宽度，以像素为单位。
				strokeOpacity: 0.7, //边线透明度，取值范围0 - 1。
				fillOpacity: 0.2, //填充的透明度，取值范围0 - 1。
				strokeStyle: 'solid', //边线的样式，solid或dashed。
			},
		}
	},
	created() {},
	mounted() {
		// 加载地图
		this.initMap()
	},
	methods: {
		// 加载地图
		initMap(data) {
			if (data) {
				this.clickPoint = data
			}
			this.map = new BMap.Map('mapbox', {
				enableMapClick: false,
				minZoom: 14,
			}) // 创建Map实例
			this.map.centerAndZoom(new BMap.Point(117.380105, 31.842386), 15)
			// 初始化地图,设置中心点坐标和地图级别
			this.map.enableScrollWheelZoom() // 开启鼠标滚轮缩放
			this.geo = new BMap.Geocoder() //创建一个地址解析器

			// 添加缩放控件
			this.map.addControl(
				new BMap.NavigationControl({
					anchor: BMAP_ANCHOR_TOP_RIGHT,
					type: BMAP_NAVIGATION_CONTROL_LARGE,
					enableGeolocation: true,
				})
			)

			// 添加城市控件
			this.map.addControl(
				new BMap.CityListControl({
					anchor: BMAP_ANCHOR_TOP_RIGHT,
					offset: new BMap.Size(90, 10),
				})
			)

			if (this.mode != 'view') {
				// 添加监听
				this.addListener()
				// init DrawManger
				this.initDraw()
			}

			// 添加区域
			this.fillAndLabel()

			if (data) {
				data.map((x) => {
					// 标记
					if (x.type == 'marker') {
						let initPoint = new BMap.Point(
							data[0].point[0].lng,
							data[0].point[0].lat
						)
						let obj = {
							overlay: {
								point: initPoint,
							},
						}
						this.initMarker(obj)
						return
					}

					// 线
					if (x.type == 'polyline') {
						let lineArr = []
						x.point.map((x) => {
							lineArr.push(new BMap.Point(x.lng, x.lat))
						})
						let polyline = new BMap.Polyline(
							lineArr,
							this.customStyle
						) //创建多边形
						this.overlays.push(polyline)
						this.map.addOverlay(polyline)
						return
					}

					// 面
					let gonArr = []
					x.point.map((x) => {
						gonArr.push(new BMap.Point(x.lng, x.lat))
					})

					let polygon = new BMap.Polygon(gonArr, this.customStyle) //创建多边形
					this.overlays.push(polygon)
					this.map.addOverlay(polygon)
				})
			}
		},

		// init DrawManger
		initDraw() {
			let that = this
			this.drawingManager = new BMapLib.DrawingManager(that.map, {
				isOpen: false, //是否开启绘制模式
				enableDrawingTool: true, //是否添加绘制工具栏控件，默认不添加
				drawingToolOptions: {
					anchor: BMAP_ANCHOR_TOP_RIGHT, //停靠位置、默认左上角
					offset: new BMap.Size(120, 10), //偏移值
					scale: 0.7, //工具栏的缩放比例,默认为1
					//设置只显示画marker、线、多边形的模式
					drawingModes: [
						BMAP_DRAWING_MARKER, //marker
						BMAP_DRAWING_POLYLINE, //线
						BMAP_DRAWING_POLYGON, //多边形
					],
				},
				polylineOptions: this.customStyle, //线的样式
				polygonOptions: this.customStyle, //多边形的样式
			})

			// 绘制结束
			let overlaycomplete = function (e) {
				let path = {
					point: [],
					address: '',
					type: '',
				}
				if (e.drawingMode != 'marker') {
					let oPath = e.overlay.getPath()
					oPath.map((x) => {
						path.point.push({
							lat: x.lat,
							lng: x.lng,
						})
					})
					let result = that.isInPolygon(oPath)
					if (!result) {
						that.map.removeOverlay(e.overlay)
						that.$message.warning('请在指定范围内选择位置！')
						return
					}
					// that.clearAll()
					that.overlays.push(e.overlay)
					that.map.removeOverlay(that.marker)
					// e.overlay.enableEditing()
					// e.overlay.addEventListener('lineupdate', function (res) {
					// 	let newPoint = res.target.getPath()
					// 	console.log(newPoint, '编辑后')
					// })

					path.type = e.drawingMode
					that.$emit('sendPoint', path)
				} else {
					let result = that.isInPolygon(e)
					if (!result) {
						that.map.removeOverlay(e.overlay)
						that.$message.warning('请在指定范围内选择位置！')
						return
					}
					that.overlays.push(e.overlay)
					that.geo.getLocation(e.overlay.point, function (rs) {
						let addComp = rs.addressComponents
						let addr =
							addComp.province +
							addComp.city +
							addComp.district +
							addComp.street +
							addComp.streetNumber
						path = {
							point: [
								{
									lat: e.overlay.point.lat,
									lng: e.overlay.point.lng,
								},
							],
							address: addr,
							type: 'marker',
						}
						that.initMarker(e)
						that.map.panTo(e.overlay.point)
						that.$emit('sendPoint', path)
					})
				}
			}

			//添加鼠标绘制工具监听事件，用于获取绘制结果
			that.drawingManager.addEventListener(
				'overlaycomplete',
				overlaycomplete
			)

			that.drawingManager.open()
		},

		// 创建标注
		initMarker(e) {
			this.clearAll()
			this.map.removeOverlay(this.marker)
			this.marker = new BMap.Marker(e.overlay.point) // 创建标注
			this.map.addOverlay(this.marker) // 将标注添加到地图中
			this.marker.setAnimation(BMAP_ANIMATION_BOUNCE) // 跳动的动画
			this.getLocation(e)
		},

		// 获取地址并弹窗
		getLocation(e) {
			let that = this
			that.geo.getLocation(e.overlay.point, function (rs) {
				let addComp = rs.addressComponents
				let addr =
					addComp.province +
					addComp.city +
					addComp.district +
					addComp.street +
					addComp.streetNumber
				that.infoWindow = new BMap.InfoWindow(addr) // 创建信息窗口对象
				that.marker.openInfoWindow(that.infoWindow, e.overlay.point) // 开启信息窗口
				that.marker.addEventListener('click', function () {
					that.marker.openInfoWindow(that.infoWindow, e.overlay.point) // 开启信息窗口
				})
			})
		},

		// 清除所有的覆盖物
		clearAll(type) {
			let that = this
			if (type == 'marker') {
				that.map.removeOverlay(that.marker)
				that.$emit('sendPoint', {
					point: [],
					address: '',
					type: '',
					noData: true,
				})
			}
			for (let i = 0; i < that.overlays.length; i++) {
				that.map.removeOverlay(that.overlays[i])
			}
			that.overlays.length = 0
		},

		// 回到初始位置
		geolocationIcon() {
			this.map.reset()
		},

		// 填充并加文字
		fillAndLabel() {
			this.fillArea()
			this.setStyle(this.polygonBh, '包河区', '#d67b1e')
			this.setStyle(this.polygonYh, '瑶海区', '#105f6c')
			this.setStyle(this.polygonFd, '肥东县', '#086e22')
			this.setStyle(this.polygonFd1, '肥东县', '#086e22')
		},

		// 填充区域
		fillArea() {
			this.polygonBh = new BMap.Polygon(this.bhPoint, this.bhColor)
			this.polygonYh = new BMap.Polygon(this.yhPoint, this.yhColor)
			this.polygonFd = new BMap.Polygon(this.fdPoint, this.fdColor)
			this.polygonFd1 = new BMap.Polygon(this.fdPoint1, this.fdColor1)
			this.map.addOverlay(this.polygonBh)
			this.map.addOverlay(this.polygonYh)
			this.map.addOverlay(this.polygonFd)
			this.map.addOverlay(this.polygonFd1)
		},

		// 设置label
		setStyle(box, title, color) {
			let point = box.getBounds().getCenter()
			let label = new BMap.Label(title, {
				position: point,
			})
			let fontSize = this.zoom * 1.5 + 'px'
			fontSize = this.zoom >= 13 ? fontSize : 0
			label.setStyle({
				color: color,
				backgroundColor: 'transparent',
				borderRadius: '10px',
				padding: '0 10px',
				fontSize: fontSize,
				border: '0',
				transform: 'translateX(-50%)',
			})
			this.map.addOverlay(label)
		},

		// 是不是在范围内
		isInPolygon(e) {
			let result = null
			if (e.overlay) {
				let { lng, lat } = e.overlay.point
				let point = new BMap.Point(lng, lat)
				result = this.computedResult(point)
			} else {
				let arr = []
				e.map((x) => {
					let dot = new BMap.Point(x.lng, x.lat)
					let res = this.computedResult(dot)
					arr.push(res)
				})
				let finalResult = arr.filter((x) => x)
				result = finalResult.length == arr.length ? true : false
			}
			return result
		},

		// 点是否在指定的多边形内
		computedResult(point) {
			let polygonYh = new BMap.Polygon(this.yhPoint)
			let polygonBh = new BMap.Polygon(this.bhPoint)
			let polygonFd = new BMap.Polygon(this.fdPoint)
			let polygonFd1 = new BMap.Polygon(this.fdPoint1)
			let result1 = BMapLib.GeoUtils.isPointInPolygon(point, polygonYh)
			let result2 = BMapLib.GeoUtils.isPointInPolygon(point, polygonBh)
			let result3 = BMapLib.GeoUtils.isPointInPolygon(point, polygonFd)
			let result4 = BMapLib.GeoUtils.isPointInPolygon(point, polygonFd1)
			let result = null
			if (result1 || result2 || result3 || result4) {
				result = true
			} else {
				result = false
			}
			return result
		},

		// 添加监听
		addListener() {
			let that = this
			let ele = document.getElementById('searchInput')
			ele.style.display = 'block'
			let ac = new BMap.Autocomplete({
				input: 'searchInput',
				location: that.map,
			})

			let myValue = ''
			ac.addEventListener('onconfirm', function (e) {
				// 鼠标点击下拉列表后的事件
				let _value = e.item.value
				myValue =
					_value.province +
					_value.city +
					_value.district +
					_value.street +
					_value.business
				setPlace()
			})

			function setPlace() {
				let local = new BMap.LocalSearch(that.map, {
					// 智能搜索
					onSearchComplete: myFun,
				})
				local.search(myValue)
				function myFun() {
					let pp = local.getResults().getPoi(0).point // 获取第一个智能搜索的结果
					let obj = {
						overlay: {
							point: pp,
						},
					}
					// 定位的点不在范围内，不给他定位
					let result = that.isInPolygon(obj)
					if (!result) {
						that.$message.warning('请在指定范围内选择位置！')
						return
					}
					that.map.centerAndZoom(pp, 15)
					that.initMarker(obj)
				}
			}
		},
	},
	computed: {
		bhPoint() {
			let arr = [
				new BMap.Point(117.328332, 31.840555),
				new BMap.Point(117.328547, 31.840938),
				new BMap.Point(117.330308, 31.843116),
				new BMap.Point(117.331853, 31.844925),
				new BMap.Point(117.333829, 31.847441),
				new BMap.Point(117.336236, 31.850324),
				new BMap.Point(117.337922, 31.84938),
				new BMap.Point(117.339787, 31.848668),
				new BMap.Point(117.340736, 31.848482),
				new BMap.Point(117.341651, 31.848058),
				new BMap.Point(117.34263, 31.847462),
				new BMap.Point(117.343263, 31.847019),
				new BMap.Point(117.34437, 31.845897),
				new BMap.Point(117.344667, 31.845465),
				new BMap.Point(117.344853, 31.845015),
				new BMap.Point(117.345255, 31.843478),
				new BMap.Point(117.345541, 31.842542),
				new BMap.Point(117.34585, 31.841812),
				new BMap.Point(117.346156, 31.841332),
				new BMap.Point(117.346566, 31.840983),
				new BMap.Point(117.347095, 31.840657),
				new BMap.Point(117.348011, 31.840231),
				new BMap.Point(117.348821, 31.839921),
				new BMap.Point(117.350942, 31.839319),
				new BMap.Point(117.352842, 31.838513),
				new BMap.Point(117.354103, 31.838),
				new BMap.Point(117.354987, 31.837629),
				new BMap.Point(117.355659, 31.837196),
				new BMap.Point(117.3564, 31.836586),
				new BMap.Point(117.356965, 31.836092),
				new BMap.Point(117.357882, 31.835291),
				new BMap.Point(117.358337, 31.83503),
				new BMap.Point(117.358956, 31.834534),
				new BMap.Point(117.35954, 31.834326),
				new BMap.Point(117.360354, 31.834347),
				new BMap.Point(117.361115, 31.834649),
				new BMap.Point(117.36259, 31.835567),
				new BMap.Point(117.363275, 31.83586),
				new BMap.Point(117.363717, 31.835933),
				new BMap.Point(117.364128, 31.835853),
				new BMap.Point(117.364346, 31.835757),
				new BMap.Point(117.364512, 31.835545),
				new BMap.Point(117.366412, 31.832407),
				new BMap.Point(117.366959, 31.831845),
				new BMap.Point(117.367905, 31.831398),
				new BMap.Point(117.368937, 31.831225),
				new BMap.Point(117.369933, 31.831237),
				new BMap.Point(117.371935, 31.831695),
				new BMap.Point(117.373403, 31.832016),
				new BMap.Point(117.373923, 31.832109),
				new BMap.Point(117.374806, 31.832223),
				new BMap.Point(117.375444, 31.832219),
				new BMap.Point(117.376186, 31.832096),
				new BMap.Point(117.376683, 31.831946),
				new BMap.Point(117.377321, 31.831735),
				new BMap.Point(117.377995, 31.831317),
				new BMap.Point(117.378421, 31.83093),
				new BMap.Point(117.378884, 31.830132),
				new BMap.Point(117.379171, 31.829217),
				new BMap.Point(117.379283, 31.827626),
				new BMap.Point(117.379501, 31.82571),
				new BMap.Point(117.379842, 31.824456),
				new BMap.Point(117.380069, 31.823983),
				new BMap.Point(117.380942, 31.823244),
				new BMap.Point(117.381599, 31.822952),
				new BMap.Point(117.382955, 31.822738),
				new BMap.Point(117.385798, 31.822449),
				new BMap.Point(117.386665, 31.822346),
				new BMap.Point(117.387265, 31.82164),
				new BMap.Point(117.387579, 31.820583),
				new BMap.Point(117.386549, 31.814228),
				new BMap.Point(117.386598, 31.813646),
				// 折点
				new BMap.Point(117.378916, 31.811731),
				new BMap.Point(117.370436, 31.810412),
				new BMap.Point(117.362962, 31.809706),
				new BMap.Point(117.354554, 31.80943),
				new BMap.Point(117.349757, 31.809108),
				new BMap.Point(117.344834, 31.80877),
				new BMap.Point(117.344079, 31.817584),
				new BMap.Point(117.343019, 31.82372),
				new BMap.Point(117.342049, 31.828997),
				new BMap.Point(117.341519, 31.832824),
				new BMap.Point(117.334494, 31.836935),
				new BMap.Point(117.331844, 31.838561),
			]
			return arr
		},
		yhPoint() {
			let arr = [
				new BMap.Point(117.336236, 31.850324),
				new BMap.Point(117.338033, 31.852502),
				new BMap.Point(117.338033, 31.852502),
				new BMap.Point(117.339973, 31.854925),
				new BMap.Point(117.339973, 31.854925),
				new BMap.Point(117.341195, 31.857593),
				new BMap.Point(117.341577, 31.859797),
				new BMap.Point(117.341807, 31.860608),
				new BMap.Point(117.342126, 31.861371),
				new BMap.Point(117.3424, 31.862011),
				new BMap.Point(117.342692, 31.862762),
				new BMap.Point(117.342939, 31.863306),
				new BMap.Point(117.343923, 31.86308),
				new BMap.Point(117.35156, 31.861044),
				new BMap.Point(117.357339, 31.859618),
				new BMap.Point(117.35901, 31.859169),
				new BMap.Point(117.360838, 31.85864),
				new BMap.Point(117.363834, 31.857873),
				new BMap.Point(117.366125, 31.857363),
				new BMap.Point(117.367207, 31.857103),
				new BMap.Point(117.368712, 31.856685),
				new BMap.Point(117.369943, 31.856344),
				new BMap.Point(117.371317, 31.855938),
				new BMap.Point(117.373212, 31.855351),
				new BMap.Point(117.375359, 31.854764),
				new BMap.Point(117.37716, 31.854281),
				new BMap.Point(117.379163, 31.853763),
				new BMap.Point(117.379275, 31.854024),
				new BMap.Point(117.379729, 31.855194),
				new BMap.Point(117.380079, 31.856179),
				new BMap.Point(117.380393, 31.857011),
				new BMap.Point(117.380658, 31.857674),
				new BMap.Point(117.38099, 31.858418),
				new BMap.Point(117.381273, 31.859047),
				new BMap.Point(117.381538, 31.859668),
				new BMap.Point(117.381767, 31.860182),
				new BMap.Point(117.382117, 31.860987),
				new BMap.Point(117.382351, 31.861508),
				new BMap.Point(117.382634, 31.862148),
				new BMap.Point(117.382841, 31.862899),
				new BMap.Point(117.382872, 31.863746),
				new BMap.Point(117.382863, 31.864988),
				new BMap.Point(117.382814, 31.866088),
				new BMap.Point(117.404104, 31.866495),
				new BMap.Point(117.404194, 31.864692),
				new BMap.Point(117.403857, 31.864591),
				new BMap.Point(117.403522, 31.864459),
				new BMap.Point(117.402704, 31.865165),
				new BMap.Point(117.402223, 31.865413),
				new BMap.Point(117.401752, 31.865998),
				new BMap.Point(117.400987, 31.86583),
				new BMap.Point(117.40068, 31.865521),
				new BMap.Point(117.399391, 31.86633),
				new BMap.Point(117.398956, 31.866137),
				new BMap.Point(117.39884, 31.865007),
				new BMap.Point(117.397867, 31.865826),
				new BMap.Point(117.397116, 31.86572),
				new BMap.Point(117.397138, 31.865113),
				new BMap.Point(117.396027, 31.864015),
				new BMap.Point(117.394947, 31.863502),
				new BMap.Point(117.394418, 31.863294),
				new BMap.Point(117.3942, 31.863177),
				new BMap.Point(117.394331, 31.862449),
				new BMap.Point(117.394295, 31.861641),
				new BMap.Point(117.394324, 31.861624),
				new BMap.Point(117.394522, 31.861613),
				new BMap.Point(117.395477, 31.861419),
				new BMap.Point(117.395499, 31.861396),
				new BMap.Point(117.395482, 31.86059),
				new BMap.Point(117.396278, 31.860219),
				new BMap.Point(117.396237, 31.859818),
				new BMap.Point(117.395156, 31.859871),
				new BMap.Point(117.394848, 31.859246),
				new BMap.Point(117.396233, 31.85905),
				new BMap.Point(117.395018, 31.858542),
				new BMap.Point(117.394743, 31.858062),
				new BMap.Point(117.394639, 31.857801),
				new BMap.Point(117.394706, 31.857176),
				new BMap.Point(117.394586, 31.856601),
				new BMap.Point(117.394536, 31.856443),
				new BMap.Point(117.394388, 31.856304),
				new BMap.Point(117.394371, 31.856238),
				new BMap.Point(117.394364, 31.85619),
				new BMap.Point(117.394438, 31.855899),
				new BMap.Point(117.394483, 31.855727),
				new BMap.Point(117.394457, 31.855315),
				new BMap.Point(117.394446, 31.855241),
				new BMap.Point(117.394075, 31.854893),
				new BMap.Point(117.39406, 31.854868),
				new BMap.Point(117.39406, 31.854854),
				new BMap.Point(117.394222, 31.854721),
				new BMap.Point(117.394496, 31.854591),
				new BMap.Point(117.39587, 31.854483),
				new BMap.Point(117.396278, 31.854683),
				new BMap.Point(117.396533, 31.854815),
				new BMap.Point(117.397127, 31.854614),
				new BMap.Point(117.39714, 31.854387),
				new BMap.Point(117.397714, 31.854178),
				new BMap.Point(117.397942, 31.853646),
				new BMap.Point(117.397885, 31.852542),
				new BMap.Point(117.397891, 31.852504),
				new BMap.Point(117.397898, 31.852494),
				new BMap.Point(117.398538, 31.852445),
				new BMap.Point(117.398569, 31.852463),
				new BMap.Point(117.398571, 31.853202),
				new BMap.Point(117.398796, 31.85366),
				new BMap.Point(117.399112, 31.854313),
				new BMap.Point(117.399934, 31.853732),
				new BMap.Point(117.401072, 31.854061),
				new BMap.Point(117.401792, 31.854361),
				new BMap.Point(117.401715, 31.855574),
				new BMap.Point(117.402131, 31.855429),
				new BMap.Point(117.402301, 31.854351),
				new BMap.Point(117.402349, 31.854309),
				new BMap.Point(117.402999, 31.854253),
				new BMap.Point(117.403112, 31.854316),
				new BMap.Point(117.403505, 31.854737),
				new BMap.Point(117.40351, 31.854862),
				new BMap.Point(117.403328, 31.855528),
				new BMap.Point(117.403204, 31.85588),
				new BMap.Point(117.40354, 31.856153),
				new BMap.Point(117.40456, 31.855378),
				new BMap.Point(117.40477, 31.851912),

				new BMap.Point(117.404846, 31.851283),
				new BMap.Point(117.404882, 31.850975),
				new BMap.Point(117.404963, 31.849384),
				new BMap.Point(117.405021, 31.848276),
				new BMap.Point(117.405102, 31.847206),
				new BMap.Point(117.405165, 31.846121),
				new BMap.Point(117.405223, 31.844875),
				new BMap.Point(117.405254, 31.843778),
				new BMap.Point(117.405276, 31.842632),
				new BMap.Point(117.405254, 31.841647),
				new BMap.Point(117.405106, 31.840769),
				// 重复点
				new BMap.Point(117.404845, 31.839895),
				new BMap.Point(117.404899, 31.83973),
				new BMap.Point(117.405416, 31.839577),
				new BMap.Point(117.40631, 31.839309),
				new BMap.Point(117.407675, 31.838933),
				new BMap.Point(117.407499, 31.838451),
				new BMap.Point(117.407475, 31.838399),
				new BMap.Point(117.407472, 31.838354),
				new BMap.Point(117.407884, 31.838088),
				new BMap.Point(117.407855, 31.83768),
				new BMap.Point(117.407671, 31.837505),
				new BMap.Point(117.407271, 31.837629),
				new BMap.Point(117.406822, 31.837382),
				new BMap.Point(117.406634, 31.837555),
				new BMap.Point(117.406551, 31.83788),
				new BMap.Point(117.406107, 31.837697),
				new BMap.Point(117.406107, 31.837697),
				new BMap.Point(117.405807, 31.83747),
				new BMap.Point(117.404991, 31.83719),
				new BMap.Point(117.404777, 31.836813),
				new BMap.Point(117.404239, 31.836596),
				new BMap.Point(117.403857, 31.83602),
				new BMap.Point(117.403342, 31.835403),
				new BMap.Point(117.403185, 31.835274),
				new BMap.Point(117.402807, 31.835026),
				new BMap.Point(117.402587, 31.834198),
				new BMap.Point(117.402577, 31.834184),
				new BMap.Point(117.402078, 31.834259),
				new BMap.Point(117.401626, 31.834324),
				new BMap.Point(117.401352, 31.834058),
				new BMap.Point(117.401305, 31.834026),
				new BMap.Point(117.400044, 31.834492),
				new BMap.Point(117.399956, 31.835034),
				new BMap.Point(117.399921, 31.835102),
				new BMap.Point(117.399431, 31.835237),
				new BMap.Point(117.399171, 31.835226),
				new BMap.Point(117.398959, 31.835226),
				new BMap.Point(117.399282, 31.835673),
				new BMap.Point(117.398759, 31.835889),
				new BMap.Point(117.398149, 31.835472),
				new BMap.Point(117.397811, 31.835608),
				new BMap.Point(117.398228, 31.836158),
				new BMap.Point(117.397903, 31.836411),
				new BMap.Point(117.397341, 31.836167),
				new BMap.Point(117.396954, 31.836011),
				new BMap.Point(117.396148, 31.836031),
				new BMap.Point(117.395967, 31.836109),
				new BMap.Point(117.395906, 31.836189),
				new BMap.Point(117.395901, 31.836217),
				new BMap.Point(117.395977, 31.836679),
				new BMap.Point(117.39549, 31.836971),
				new BMap.Point(117.394887, 31.837015),
				new BMap.Point(117.394835, 31.837032),
				new BMap.Point(117.39469, 31.837229),
				new BMap.Point(117.394298, 31.83773),
				new BMap.Point(117.394008, 31.837632),
				new BMap.Point(117.39374, 31.837547),
				new BMap.Point(117.393508, 31.837479),
				new BMap.Point(117.393179, 31.837368),
				new BMap.Point(117.393121, 31.837337),
				new BMap.Point(117.39307, 31.837252),
				new BMap.Point(117.393417, 31.83668),
				new BMap.Point(117.394099, 31.836274),
				new BMap.Point(117.394671, 31.83614),
				new BMap.Point(117.394882, 31.835658),
				new BMap.Point(117.394634, 31.835593),
				new BMap.Point(117.394308, 31.835527),
				new BMap.Point(117.393727, 31.835194),
				new BMap.Point(117.393567, 31.835136),
				new BMap.Point(117.392967, 31.83495),
				new BMap.Point(117.392919, 31.834915),
				new BMap.Point(117.392884, 31.834442),
				new BMap.Point(117.392407, 31.833982),
				new BMap.Point(117.392001, 31.833966),
				new BMap.Point(117.391826, 31.834355),
				new BMap.Point(117.390805, 31.834904),
				new BMap.Point(117.389597, 31.835537),
				new BMap.Point(117.389193, 31.835251),
				new BMap.Point(117.388978, 31.834742),
				new BMap.Point(117.388942, 31.834124),
				new BMap.Point(117.388854, 31.833849),
				new BMap.Point(117.388678, 31.833383),
				new BMap.Point(117.388604, 31.832523),
				new BMap.Point(117.388618, 31.832478),
				new BMap.Point(117.38885, 31.832302),
				new BMap.Point(117.389386, 31.832107),
				new BMap.Point(117.389233, 31.831629),
				new BMap.Point(117.389186, 31.831609),
				new BMap.Point(117.388913, 31.831533),
				new BMap.Point(117.388586, 31.8307),
				new BMap.Point(117.388615, 31.829178),
				new BMap.Point(117.3886, 31.828794),
				new BMap.Point(117.388844, 31.828526),
				new BMap.Point(117.388823, 31.828276),
				new BMap.Point(117.388644, 31.828162),
				new BMap.Point(117.388316, 31.828049),
				new BMap.Point(117.388004, 31.827937),
				new BMap.Point(117.387898, 31.827478),
				new BMap.Point(117.387805, 31.826107),
				new BMap.Point(117.38768, 31.825499),
				new BMap.Point(117.387274, 31.825297),
				new BMap.Point(117.387222, 31.825292),
				new BMap.Point(117.386723, 31.825404),
				new BMap.Point(117.386157, 31.825411),
				new BMap.Point(117.386124, 31.825396),
				new BMap.Point(117.386072, 31.825244),
				new BMap.Point(117.386025, 31.824971),
				new BMap.Point(117.386035, 31.824941),
				new BMap.Point(117.386195, 31.824807),
				new BMap.Point(117.386228, 31.824684),
				new BMap.Point(117.38631, 31.824173),
				new BMap.Point(117.386393, 31.822682),
				new BMap.Point(117.386665, 31.822346),
				new BMap.Point(117.385798, 31.822449),
				new BMap.Point(117.382955, 31.822738),
				new BMap.Point(117.381599, 31.822952),
				new BMap.Point(117.380942, 31.823244),
				new BMap.Point(117.380069, 31.823983),
				new BMap.Point(117.379842, 31.824456),
				new BMap.Point(117.379501, 31.82571),
				new BMap.Point(117.379283, 31.827626),
				new BMap.Point(117.379171, 31.829217),
				new BMap.Point(117.378884, 31.830132),
				new BMap.Point(117.378421, 31.83093),
				new BMap.Point(117.377995, 31.831317),
				new BMap.Point(117.377321, 31.831735),
				new BMap.Point(117.376683, 31.831946),
				new BMap.Point(117.376186, 31.832096),
				new BMap.Point(117.375444, 31.832219),
				new BMap.Point(117.374806, 31.832223),
				new BMap.Point(117.373923, 31.832109),
				new BMap.Point(117.373403, 31.832016),
				new BMap.Point(117.371935, 31.831695),
				new BMap.Point(117.369933, 31.831237),
				new BMap.Point(117.368937, 31.831225),
				new BMap.Point(117.367905, 31.831398),
				new BMap.Point(117.366959, 31.831845),
				new BMap.Point(117.366412, 31.832407),
				new BMap.Point(117.364512, 31.835545),
				new BMap.Point(117.364346, 31.835757),
				new BMap.Point(117.364128, 31.835853),
				new BMap.Point(117.363717, 31.835933),
				new BMap.Point(117.363275, 31.83586),
				new BMap.Point(117.36259, 31.835567),
				new BMap.Point(117.361115, 31.834649),
				new BMap.Point(117.360354, 31.834347),
				new BMap.Point(117.35954, 31.834326),
				new BMap.Point(117.358956, 31.834534),
				new BMap.Point(117.358337, 31.83503),
				new BMap.Point(117.357882, 31.835291),
				new BMap.Point(117.356965, 31.836092),
				new BMap.Point(117.3564, 31.836586),
				new BMap.Point(117.355659, 31.837196),
				new BMap.Point(117.354987, 31.837629),
				new BMap.Point(117.354103, 31.838),
				new BMap.Point(117.352842, 31.838513),
				new BMap.Point(117.350942, 31.839319),
				new BMap.Point(117.348821, 31.839921),
				new BMap.Point(117.348011, 31.840231),
				new BMap.Point(117.347095, 31.840657),
				new BMap.Point(117.346566, 31.840983),
				new BMap.Point(117.346156, 31.841332),
				new BMap.Point(117.34585, 31.841812),
				new BMap.Point(117.345541, 31.842542),
				new BMap.Point(117.345255, 31.843478),
				new BMap.Point(117.344853, 31.845015),
				new BMap.Point(117.344667, 31.845465),
				new BMap.Point(117.34437, 31.845897),
				new BMap.Point(117.343263, 31.847019),
				new BMap.Point(117.34263, 31.847462),
				new BMap.Point(117.341651, 31.848058),
				new BMap.Point(117.340736, 31.848482),
				new BMap.Point(117.339787, 31.848668),
				new BMap.Point(117.337922, 31.84938),
			]
			return arr
		},
		fdPoint() {
			let arr = [
				new BMap.Point(117.407675, 31.838933),
				new BMap.Point(117.407499, 31.838451),
				new BMap.Point(117.407475, 31.838399),
				new BMap.Point(117.407472, 31.838354),
				new BMap.Point(117.407884, 31.838088),
				new BMap.Point(117.407855, 31.83768),
				new BMap.Point(117.407671, 31.837505),
				new BMap.Point(117.407271, 31.837629),
				new BMap.Point(117.406822, 31.837382),
				new BMap.Point(117.406634, 31.837555),
				new BMap.Point(117.406551, 31.83788),
				new BMap.Point(117.406107, 31.837697),
				new BMap.Point(117.406107, 31.837697),
				new BMap.Point(117.405807, 31.83747),
				new BMap.Point(117.404991, 31.83719),
				new BMap.Point(117.404777, 31.836813),
				new BMap.Point(117.404239, 31.836596),
				new BMap.Point(117.403857, 31.83602),
				new BMap.Point(117.403342, 31.835403),
				new BMap.Point(117.403185, 31.835274),
				new BMap.Point(117.402807, 31.835026),
				new BMap.Point(117.402587, 31.834198),
				new BMap.Point(117.402577, 31.834184),
				new BMap.Point(117.402078, 31.834259),
				new BMap.Point(117.401626, 31.834324),
				new BMap.Point(117.401352, 31.834058),
				new BMap.Point(117.401305, 31.834026),
				new BMap.Point(117.400044, 31.834492),
				new BMap.Point(117.399956, 31.835034),
				new BMap.Point(117.399921, 31.835102),
				new BMap.Point(117.399431, 31.835237),
				new BMap.Point(117.399171, 31.835226),
				new BMap.Point(117.398959, 31.835226),
				new BMap.Point(117.399282, 31.835673),
				new BMap.Point(117.398759, 31.835889),
				new BMap.Point(117.398149, 31.835472),
				new BMap.Point(117.397811, 31.835608),
				new BMap.Point(117.398228, 31.836158),
				new BMap.Point(117.397903, 31.836411),
				new BMap.Point(117.397341, 31.836167),
				new BMap.Point(117.396954, 31.836011),
				new BMap.Point(117.396148, 31.836031),
				new BMap.Point(117.395967, 31.836109),
				new BMap.Point(117.395906, 31.836189),
				new BMap.Point(117.395901, 31.836217),
				new BMap.Point(117.395977, 31.836679),
				new BMap.Point(117.39549, 31.836971),
				new BMap.Point(117.394887, 31.837015),
				new BMap.Point(117.394835, 31.837032),
				new BMap.Point(117.39469, 31.837229),
				new BMap.Point(117.394298, 31.83773),
				new BMap.Point(117.394008, 31.837632),
				new BMap.Point(117.39374, 31.837547),
				new BMap.Point(117.393508, 31.837479),
				new BMap.Point(117.393179, 31.837368),
				new BMap.Point(117.393121, 31.837337),
				new BMap.Point(117.39307, 31.837252),
				new BMap.Point(117.393417, 31.83668),
				new BMap.Point(117.394099, 31.836274),
				new BMap.Point(117.394671, 31.83614),
				new BMap.Point(117.394882, 31.835658),
				new BMap.Point(117.394634, 31.835593),
				new BMap.Point(117.394308, 31.835527),
				new BMap.Point(117.393727, 31.835194),
				new BMap.Point(117.393567, 31.835136),
				new BMap.Point(117.392967, 31.83495),
				new BMap.Point(117.392919, 31.834915),
				new BMap.Point(117.392884, 31.834442),
				new BMap.Point(117.392407, 31.833982),
				new BMap.Point(117.392001, 31.833966),
				new BMap.Point(117.391826, 31.834355),
				new BMap.Point(117.390805, 31.834904),
				new BMap.Point(117.389597, 31.835537),
				new BMap.Point(117.389193, 31.835251),
				new BMap.Point(117.388978, 31.834742),
				new BMap.Point(117.388942, 31.834124),
				new BMap.Point(117.388854, 31.833849),
				new BMap.Point(117.388678, 31.833383),
				new BMap.Point(117.388604, 31.832523),
				new BMap.Point(117.388618, 31.832478),
				new BMap.Point(117.38885, 31.832302),
				new BMap.Point(117.389386, 31.832107),
				new BMap.Point(117.389233, 31.831629),
				new BMap.Point(117.389186, 31.831609),
				new BMap.Point(117.388913, 31.831533),
				new BMap.Point(117.388586, 31.8307),
				new BMap.Point(117.388615, 31.829178),
				new BMap.Point(117.3886, 31.828794),
				new BMap.Point(117.388844, 31.828526),
				new BMap.Point(117.388823, 31.828276),
				new BMap.Point(117.388644, 31.828162),
				new BMap.Point(117.388316, 31.828049),
				new BMap.Point(117.388004, 31.827937),
				new BMap.Point(117.387898, 31.827478),
				new BMap.Point(117.387805, 31.826107),
				new BMap.Point(117.38768, 31.825499),
				new BMap.Point(117.387274, 31.825297),
				new BMap.Point(117.387222, 31.825292),
				new BMap.Point(117.386723, 31.825404),
				new BMap.Point(117.386157, 31.825411),
				new BMap.Point(117.386124, 31.825396),
				new BMap.Point(117.386072, 31.825244),
				new BMap.Point(117.386025, 31.824971),
				new BMap.Point(117.386035, 31.824941),
				new BMap.Point(117.386195, 31.824807),
				new BMap.Point(117.386228, 31.824684),
				new BMap.Point(117.38631, 31.824173),
				new BMap.Point(117.386393, 31.822682),
				new BMap.Point(117.386665, 31.822346),
				new BMap.Point(117.387265, 31.82164),
				new BMap.Point(117.387579, 31.820583),
				new BMap.Point(117.386549, 31.814228),
				new BMap.Point(117.386598, 31.813646),
				new BMap.Point(117.387797, 31.813972),
				new BMap.Point(117.388619, 31.81421),
				new BMap.Point(117.389414, 31.814452),
				new BMap.Point(117.390146, 31.814686),
				new BMap.Point(117.390905, 31.814931),
				new BMap.Point(117.39183, 31.815242),
				new BMap.Point(117.39311, 31.815695),
				new BMap.Point(117.393932, 31.815983),
				new BMap.Point(117.394767, 31.816294),
				new BMap.Point(117.395499, 31.81657),
				new BMap.Point(117.396447, 31.816942),
				new BMap.Point(117.397413, 31.81731),
				new BMap.Point(117.398311, 31.81769),
				new BMap.Point(117.399182, 31.818058),
				new BMap.Point(117.399955, 31.818376),
				new BMap.Point(117.400808, 31.818752),
				new BMap.Point(117.401774, 31.819197),
				new BMap.Point(117.402933, 31.81978),
				new BMap.Point(117.403872, 31.820213),
				new BMap.Point(117.404613, 31.820593),
				new BMap.Point(117.405444, 31.821023),
				new BMap.Point(117.406491, 31.821594),
				new BMap.Point(117.407578, 31.822204),
				new BMap.Point(117.408589, 31.822783),
				new BMap.Point(117.409451, 31.823278),
				new BMap.Point(117.410596, 31.823968),
				new BMap.Point(117.411759, 31.824716),
				new BMap.Point(117.412846, 31.825406),
				new BMap.Point(117.413915, 31.826138),
				new BMap.Point(117.414908, 31.826824),
				new BMap.Point(117.415878, 31.827522),
				new BMap.Point(117.416911, 31.828285),
				new BMap.Point(117.418474, 31.829478),
				new BMap.Point(117.419516, 31.830287),
				new BMap.Point(117.420495, 31.831008),
				new BMap.Point(117.421542, 31.831825),
				new BMap.Point(117.422719, 31.832715),
				new BMap.Point(117.423707, 31.833497),
				new BMap.Point(117.424623, 31.834157),
				new BMap.Point(117.425427, 31.834794),
				new BMap.Point(117.42637, 31.8355),
				new BMap.Point(117.426325, 31.835496),
				new BMap.Point(117.427174, 31.836159),
				new BMap.Point(117.427951, 31.836803),
				new BMap.Point(117.427964, 31.836922),
				new BMap.Point(117.42765, 31.836995),
				new BMap.Point(117.426729, 31.837229),
				new BMap.Point(117.425458, 31.837547),
				new BMap.Point(117.424416, 31.837842),
				new BMap.Point(117.42354, 31.838064),
				new BMap.Point(117.422529, 31.838283),
				new BMap.Point(117.42177, 31.838367),
				new BMap.Point(117.420723, 31.838432),
				new BMap.Point(117.419376, 31.838436),
				new BMap.Point(117.418199, 31.838444),
				new BMap.Point(117.417157, 31.83844),
				new BMap.Point(117.415877, 31.838455),
				new BMap.Point(117.414889, 31.83847),
				new BMap.Point(117.413708, 31.83847),
				new BMap.Point(117.412248, 31.83847),
				new BMap.Point(117.411345, 31.83847),
				new BMap.Point(117.410366, 31.83847),
				new BMap.Point(117.409131, 31.838627),
				new BMap.Point(117.408201, 31.838811),
				new BMap.Point(117.407675, 31.838933),
			]
			return arr
		},
		fdPoint1() {
			let arr = [
				new BMap.Point(117.404194, 31.864692),
				new BMap.Point(117.403857, 31.864591),
				new BMap.Point(117.403522, 31.864459),
				new BMap.Point(117.402704, 31.865165),
				new BMap.Point(117.402223, 31.865413),
				new BMap.Point(117.401752, 31.865998),
				new BMap.Point(117.400987, 31.86583),
				new BMap.Point(117.40068, 31.865521),
				new BMap.Point(117.399391, 31.86633),
				new BMap.Point(117.398956, 31.866137),
				new BMap.Point(117.39884, 31.865007),
				new BMap.Point(117.397867, 31.865826),
				new BMap.Point(117.397116, 31.86572),
				new BMap.Point(117.397138, 31.865113),
				new BMap.Point(117.396027, 31.864015),
				new BMap.Point(117.394947, 31.863502),
				new BMap.Point(117.394418, 31.863294),
				new BMap.Point(117.3942, 31.863177),
				new BMap.Point(117.394331, 31.862449),
				new BMap.Point(117.394295, 31.861641),
				new BMap.Point(117.394324, 31.861624),
				new BMap.Point(117.394522, 31.861613),
				new BMap.Point(117.395477, 31.861419),
				new BMap.Point(117.395499, 31.861396),
				new BMap.Point(117.395482, 31.86059),
				new BMap.Point(117.396278, 31.860219),
				new BMap.Point(117.396237, 31.859818),
				new BMap.Point(117.395156, 31.859871),
				new BMap.Point(117.394848, 31.859246),
				new BMap.Point(117.396233, 31.85905),
				new BMap.Point(117.395018, 31.858542),
				new BMap.Point(117.394743, 31.858062),
				new BMap.Point(117.394639, 31.857801),
				new BMap.Point(117.394706, 31.857176),
				new BMap.Point(117.394586, 31.856601),
				new BMap.Point(117.394536, 31.856443),
				new BMap.Point(117.394388, 31.856304),
				new BMap.Point(117.394371, 31.856238),
				new BMap.Point(117.394364, 31.85619),
				new BMap.Point(117.394438, 31.855899),
				new BMap.Point(117.394483, 31.855727),
				new BMap.Point(117.394457, 31.855315),
				new BMap.Point(117.394446, 31.855241),
				new BMap.Point(117.394075, 31.854893),
				new BMap.Point(117.39406, 31.854868),
				new BMap.Point(117.39406, 31.854854),
				new BMap.Point(117.394222, 31.854721),
				new BMap.Point(117.394496, 31.854591),
				new BMap.Point(117.39587, 31.854483),
				new BMap.Point(117.396278, 31.854683),
				new BMap.Point(117.396533, 31.854815),
				new BMap.Point(117.397127, 31.854614),
				new BMap.Point(117.39714, 31.854387),
				new BMap.Point(117.397714, 31.854178),
				new BMap.Point(117.397942, 31.853646),
				new BMap.Point(117.397885, 31.852542),
				new BMap.Point(117.397891, 31.852504),
				new BMap.Point(117.397898, 31.852494),
				new BMap.Point(117.398538, 31.852445),
				new BMap.Point(117.398569, 31.852463),
				new BMap.Point(117.398571, 31.853202),
				new BMap.Point(117.398796, 31.85366),
				new BMap.Point(117.399112, 31.854313),
				new BMap.Point(117.399934, 31.853732),
				new BMap.Point(117.401072, 31.854061),
				new BMap.Point(117.401792, 31.854361),
				new BMap.Point(117.401715, 31.855574),
				new BMap.Point(117.402131, 31.855429),
				new BMap.Point(117.402301, 31.854351),
				new BMap.Point(117.402349, 31.854309),
				new BMap.Point(117.402999, 31.854253),
				new BMap.Point(117.403112, 31.854316),
				new BMap.Point(117.403505, 31.854737),
				new BMap.Point(117.40351, 31.854862),
				new BMap.Point(117.403328, 31.855528),
				new BMap.Point(117.403204, 31.85588),
				new BMap.Point(117.40354, 31.856153),
				new BMap.Point(117.40456, 31.855378),
			]
			return arr
		},
	},
	beforeDestroy() {
		this.map = null
	},
}
</script>

<style lang='less' scoped>
.ProjectMapIndex-layout {
	height: 100%;
	position: relative;
	#mapbox {
		width: 100%;
		height: 100%;
		/deep/ .BMap_bubble_content {
			margin-top: 15px !important;
		}

		/deep/ .BMap_cpyCtrl {
			display: none;
		}

		/* 隐藏文字信息 */
		/deep/ .anchorBL {
			display: none;
		}
	}
	.s-result {
		position: absolute;
		left: 10px;
		top: 10px;
		z-index: 999;
		width: 400px;
	}
}
.geolocationIcon {
	width: 32px;
	height: 32px;
	cursor: pointer;
	background-image: url(http://api.map.baidu.com/images/geolocation-control/mobile/default-40x40.png);
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position: center center;
	position: absolute;
	bottom: 9px;
	right: 31px;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0px 0px 10px #777;
}
</style>

<style lang="less">
.tangram-suggestion-main {
	z-index: 99999999;
}
</style>