/**
 * 描述：我的审批接口管理
 * 录入人： 昂少强
 */
import request from '../request.js'


/**
* 描述：查询单位列表数据
* 接口管理员：郑志会
* 参数： 
{
    "tenantId": , -- --租户ID
    "size": 10,
    "current": 1,
    "status": ""-- --审核状态
    "sname": "", -- -- - 搜索条件
}
*/
export const selectApprovalPage = function (data) {
    return request({
        url: 'approveRecord/selectApprovalPage',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：审核 - 标段信息查询
* 接口管理员：郑志会
* 参数： 
{
    "tableId": , -- --标段id
}
*/
export const getSectionInfo = function (data) {
    return request({
        url: 'busPackage/selectPackageInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}
/**
* 描述：提交审核
* 接口管理员：郑志会
* 参数： 
{
    "tableId":  --标段id
    "status": '' --审核状态
    "remark": ''--审批意见
}
*/
export const getSubmitAdd = function (data) {
    return request({
        url: 'busPackageAudit/getSubmitAdd',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}
/**
* 描述：报审信息
* 接口管理员：郑志会
* 参数： 
{
    "id": --主键id
}
*/
export const getApprovalReport = function (data) {
    return request({
        url: 'busPackageAudit/getApprovalReport',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}
/**
* 描述：审计费用 -- 信息回显
* 接口管理员：李开明
* 参数： 
{
    "busId": "",
    "projectId": ""
}
*/
export const feeApplyEcho = function (data) {
    return request({
        url: '/feeApply/feeApplyEcho',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}
/**
* 描述：审计费用 -- 付款登记
* 接口管理员：李开明
* 参数： 
{
}
*/
export const paymentRegister = function (data) {
    return request({
        url: '/feeApply/paymentRegister',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}
/**
* 描述：费用管理 -- 列表
* 接口管理员：叶凡
* 参数： 
{
}
*/
export const getFeePages = function (data) {
    return request({
        url: '/feeApply/getFeePages',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 审批类型
* 接口管理员：崔北超
* 参数： 
*/
export const selectApprovalType = function (data) {
    return request({
        url: 'approveRecord/selectApprovalType',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 获取合同列表
 * 接口管理员：李开明
 */
export const selectRelationContract = function (data) {
    return request({
        url: '/feeApply/selectRelationContract',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述： 合同信息详情查看
* 接口管理员：崔北超
* 参数： 
{
    id: '', // 合同ID
}
*/
export const getContractDetails = function (data) {
    return request({
        url: '/contractBase/getContractDetails',
        method: 'post',
        data
    })
}

/**
* 描述： 我的审批--审计报告详情获取
* 接口管理员：崔北超
* 参数： 
{
    id: '', // 审批数据id	
    busId: '' //审批数据busId	
}
*/
export const getApprovalReportCheck = function (data) {
    return request({
        url: '/approveReport/getApprovalReport',
        method: 'post',
        data
    })
}

/**
* 描述： 费用管理 - 列表查询条件赋值
* 接口管理员：崔北超
* 参数： 
{
    id: '', // 审批数据id
    busId: '' //审批数据busId	
}
*/
export const pageSelectParam = function (data) {
    return request({
        url: '/feeApply/pageSelectParam',
        method: 'post',
        data
    })
}

/**
* 描述： 审计方案  ---  查看接口
* 接口管理员：崔北超
* 参数： 
{
    id: '', // 审批数据id	
    busId: '' //审批数据busId	
}
*/
export const getApprovalReportPlan = function (data) {
    return request({
        url: '/approveScheme/getApprovalReport',
        method: 'post',
        data
    })
}

/**
* 描述：合同管理--项目合同结构二三级组合
* 接口管理员：崔北超
* 参数：
{
}
*/
export const getBusProjectContract = function (data) {
    return request({
        url: '/projectContract/getBusProjectContract',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 我的审批 - 获取审批数据下人员的审批信息
* 接口管理员：崔北超
* 参数：
{
}
*/
export const selectApproveRecordById = function (data) {
    return request({
        url: '/approveRecord/selectApproveRecordById',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述： 我的审批 - 获取审批数据下人员的审批信息
* 接口管理员：崔北超
* 参数：
{
}
*/
export const getApprovalReportOpinion = function (data) {
    return request({
        url: '/approveViewBill/getViewBillData',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}